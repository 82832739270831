/* You can add global styles to this file, and also import other style files */
@use './app/styles/variables.scss' as *;
@use './app/styles/user-reports.scss' as *;
@use './app/styles/user-reports-renderer.scss' as *;

// NG-PICK-DATETIME
@import '../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

/* Font Imports */

@font-face {
	font-family: 'dotsfont';
	src: url('/assets/fonts/dotsfont/dotsfont.eot');
	src:
		url('/assets/fonts/dotsfont/dotsfont.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/dotsfont/dotsfont.woff') format('woff'),
		url('/assets/fonts/dotsfont/dotsfont.ttf') format('truetype'),
		url('/assets/fonts/dotsfont/dotsfont.svg#dotsfontregular') format('svg');
}

[conceal]:not(:active):not(:focus) {
	font-family: 'dotsfont';
}
// Begin Master Styles

html,
body {
	max-width: 100%;
	overflow-x: hidden;
	padding-right: 0px !important; // Bootstrap modal-open dialog shift fix
}

a:focus,
button:focus,
input:focus,
textarea:focus {
	outline: none !important;
	box-shadow: none !important;
}

a {
	text-decoration: none !important;
}

a:hover {
	text-decoration: none;
}

.pre-code {
	display: block;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	font-size: 0.875em;

	display: block;
	font-family: monospace;
	unicode-bidi: isolate;
	white-space: pre;
	margin: 1em 0px;

	font-size: 0.875em;
	color: var(--bs-code-color);
	word-wrap: break-word;

	background-color: #f5f5f5;
	padding: 10px;
	border-radius: 4px;
}

.inline-block {
	display: inline-block;
}

.form-control:disabled {
	scrollbar-width: none;
}

.form-select {
	box-shadow: none !important;
}

// Gray background for radio buttons in edit forms
.radio-wrap {
	background-color: #edf0f2;
	padding: 7px 0px 3px 0px;
	border-radius: 8px;
	margin: 22px 0 18px;
	text-align: center;
	.form-check-input,
	.form-check-label {
		cursor: pointer;
	}
}

// Progress Indicator Message
.ab-message-block {
	margin-top: 30px;
	font-size: 1.3em;
	text-align: center;
	color: chocolate;
}

$outline-bg-color-active: #d3c2a7;

.outline-btn {
	display: inline-block;
	cursor: pointer;
	text-align: center;
	color: #6c757d;
	background-color: transparent;
	background-image: none;
	border-color: #6c757d;
	border-radius: 0.375rem;
	user-select: none;
	border: 1px solid #6c757d;
	padding: 0.25rem 0.5rem;
}
.outline-btn-hover {
	background-color: $outline-bg-color-active;
}
.outline-btn:focus {
	outline: none;
}
.outline-btn:active {
	background-color: $outline-bg-color-active;
}

.outline-btn-success {
	color: #198754;
	border-color: #198754;
}

.outline-btn-danger {
	color: #dc3545;
	border-color: #dc3545;
}

.no-records {
	color: gray;
	border: 1px solid gray;
	border-radius: 12px;
	padding: 12px;
	font-size: 2rem;
	font-weight: 600;
	max-width: 400px;
	margin: 0px auto 0px auto;
	text-align: center;
}

.prevent-select {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.updating-note {
	i {
		position: relative;
		top: -2px;
	}
}

.tts-height {
	height: 36px;
}

.loading-block {
	margin-top: 25px;
	text-align: center;
	font-size: 1.3em;
	color: chocolate;
}

.block-events {
	pointer-events: none !important;
	cursor: default;
}

.tts-bold {
	font-weight: bold;
}
.hl-valid {
	color: green;
}
.hl-invalid {
	color: firebrick;
}
.hl-issues {
	color: chocolate;
}
.hl-gps-gray {
	color: #a8a7b3;
}
.hl-gps-green {
	color: green;
}
.hl-gps-orange {
	color: chocolate;
}
.hl-gps-red {
	color: firebrick;
}
.hl-gps-travel {
	color: #7b22b2;
}
.hl-notify-gray {
	color: #a8a7b3;
}
.hl-notify-green {
	color: green;
}
.hl-notify-red {
	color: firebrick;
}
.warn-highlight {
	color: firebrick;
}
.success-highlight {
	color: rgb(25, 150, 25);
}

.tts-dev-banner {
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 12px;
	font-size: 1.2em;
	font-weight: bold;
	text-align: center;
	// color: slategray;
	background-color: slategray;
	color: white;
}
.tts-default-font {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.tts-color-choc {
	color: chocolate;
}
.tts-color-info {
	color: #0275d8;
}
.tts-color-dark-blue {
	color: #135c92;
}
.tts-hl-1 {
	color: chocolate;
}
.tts-hl-2 {
	color: #135c94;
}
.tts-hl-3 {
	color: chocolate; // #ac28b0;
}
.tts-hl-gold {
	color: #a28643;
}

.tts-hl-slategray {
	color: slategray;
}
.tts-hl-gray {
	color: #96969e;
}
.tts-hl-green {
	color: green;
}
.tts-hl-orange {
	color: chocolate;
}
.tts-hl-red {
	color: firebrick;
}
.tts-hl-firebrick {
	color: firebrick;
}
.tts-hl-multiday {
	color: #d39e27;
}

.tts-link-color {
	color: #007bff;
}
.tts-line-height-tight {
	line-height: 1.2em !important;
}
.has-pointer {
	cursor: pointer;
}
.has-context-menu {
	cursor: context-menu;
}

.inline-text {
	display: inline-block;
}

.link-disabled {
	color: gray;
}

.link-text {
	color: #007bff;
	cursor: pointer;
}
.link-text:hover {
	color: #0056b3;
}

.link-text-dark {
	color: #0275d8;
	cursor: pointer;
}
.link-text-dark:hover {
	color: #014c8c;
}
.link-btn {
	border: 1px solid;
	border-radius: 4px;
	font-size: 0.8em;
	line-height: 1em;
	text-align: center;
}
.link-btn:hover {
	background-color: #007bff;
	color: white;
}

// Email button
.btn-email {
	color: #5c5e62 !important;
	border: 1px solid #b9c3bb !important;
	margin-bottom: 8px;
}
.btn-email:hover {
	cursor: default;
	color: #5c5e62;
	background-color: white;
}
.btn-email:focus {
	box-shadow: none;
}
.btn-email-x {
	margin-top: 2px;
	cursor: pointer;
}

// QB Log button
.btn-qb-log {
	font-weight: bold;
}
.btn-int-log {
	font-weight: bold;
}

// Phone nuumber input and list
.phone-country-select {
	height: 36px;
	line-height: 0.8rem;
}

.btn-phone {
	color: #5c5e62 !important;
	border: 1px solid #b9c3bb !important;
	margin-bottom: 8px;
}
.btn-phone:hover {
	cursor: default;
	color: #5c5e62;
	background-color: white;
}
.btn-phone:focus {
	box-shadow: none;
}
.btn-phone-x {
	margin-top: 2px;
	cursor: pointer;
}

.alert-block {
	line-height: 1.1em;
	margin-bottom: 25px;
}

// Data loading copy
.data-loading {
	text-align: center;
	font-size: 2em;
	margin-top: 40px;
	color: slategray;
}
.no-data-block {
	font-size: 1.4rem;
	margin: 20px auto;
	text-align: center;
	color: slategray;
}
.updating-note {
	margin-top: 30px;
	font-size: 1.3em;
	text-align: center;
	color: chocolate;
}

// Info button and help dialog
.tts-info-btn {
	cursor: pointer;
	color: #007bff;
	margin-left: 5px;
}
.tts-info-btn:hover {
	color: #0056b3;
}
.tts-info-msg {
	line-height: 1.4rem;
	white-space: pre-wrap;
}

// Overscroll contain on modal
.modal-body {
	overscroll-behavior-y: contain;
}

// Certain modals like checkpoints
.tts-modal {
	margin-top: 60px;
}
// Show History link
.audit-link {
	margin-top: 20px;
}
.audit-log-image {
	max-width: 100px;
	border: 1px solid gray;
	border-radius: 8px;
	margin-bottom: 12px;
	margin-right: 12px;
}

// Billing button used in banners
.btn-billing {
	border-color: white;
	margin-left: 12px;
	color: white;
	padding: 0px 4px;
	position: relative;
	top: -3px;
}
.btn-billing:active {
	color: #fff;
	background-color: #d9534f !important;
	border-color: white !important;
}
.btn-billing:hover {
	border-color: white !important;
	background-color: rgb(148, 78, 78) !important;
}
.btn-billing:focus {
	border-color: white !important;
	background-color: #d9534f !important;
}

.alert-btn-fix {
	position: relative;
	top: -2px;
	margin-left: 10px;
	padding: 0px 4px;
	border: 1px solid;
	border-radius: 4px;
	cursor: pointer;
}
.alert-btn-fix:active {
	color: white;
	background-color: #664d03;
}
.alert-btn-fix:hover {
	color: white;
	background-color: #664d03;
}
.alert-btn-fix:focus {
	color: white;
	background-color: #664d03;
}
.alert-btn-fix-danger {
	color: white;
}

.alert-danger {
	.alert-btn-fix:active {
		background-color: #58151c;
	}
	.alert-btn-fix:hover {
		background-color: #58151c;
	}
	.alert-btn-fix:focus {
		background-color: #58151c;
	}
}

// Date Picker

.date-picker-input {
	// Mimics bootstrap input field
	padding: 0.375rem 0.75rem;
	border: 1px solid #d6d6d6;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	height: calc(1.5em + 0.75rem + 2px);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;
}
.date-picker-wrapper {
	cursor: pointer;
}

.date-time-panel {
	max-width: 100px;
}
.datetime-input:focus {
	outline: none !important;
	outline-width: 0 !important;
}
.datetime-icon {
	color: #135c94;
}
.datetime-clock-icon {
	color: #135c94;
	font-size: 1.2em;
}
.cdk-overlay-container {
	z-index: 2000;
}
.owl-dialog-container {
	border-radius: 8px;
}
.owl-dt-dialog-container .owl-dt-calendar {
	min-width: 250px;
	min-height: 350px !important;
	max-width: 400px;
	max-height: 420px;
}
.owl-dt-dialog-container .owl-dt-timer {
	min-width: 250px;
	max-width: 400px;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-active .owl-dt-calendar-cell-content {
	// font-weight: bold;
	background-color: rgb(239, 240, 204);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
	color: #ffffffd9;
	background-color: #135c94 !important;
}
.owl-dt-container-buttons {
	color: #135c94;
}
.owl-dt-container-control-button:hover .owl-dt-control-button-content {
	background-color: #135c94;
	color: white;
}
.owl-dt-timer-hour12-box:hover .owl-dt-control-button-content {
	background-color: #135c94 !important;
	color: #fff;
}

.owl-dt-timer-hour12-box {
	color: #135c94 !important;
}

body.hide-dt-time-picker .owl-dt-dialog-container .owl-dt-timer {
	display: none;
}

body.hide-dt-date-picker .owl-dt-dialog-container .owl-dt-calendar {
	display: none;
}

// DEPRECATED 20240619
// .dt-open-block-events {
// 	pointer-events: none !important;
// }

// There are 2 options available to manage having both a bootstrap modal and a datetime picker
// modal open at the same time. Option 1 is to have the datetime picker hide and show the bootstrap
// dialog when picker is on screen. This is the preferred method as it prevents any click throughs
// on the picker backdrop which can trigger other elements in the dialog such as dropdowns or
// other datetime pickers. Option 2 shows both the dialog and the picker but has the issue just
// mentioned about click throughs.

// Option 1: Use this when hiding the bootstrap modal when picker is open.
body.dt-picker-open.modal-open .cdk-overlay-backdrop-showing {
	opacity: 0 !important;
}

// Option 2Use this when not hiding the bootstrap modal when picker is open.
// Pointer events still fall through if the bootstrap modal ia visible and another
// datepicker could be opened which will cause problems.
// body.dt-picker-open.modal-open {
// 	.modal-backdrop {
// 		opacity: 0.5 !important;
// 		pointer-events: none;
// 	}
// 	.cdk-overlay-backdrop-showing {
// 		opacity: 0.5;
// 		color: black;
// 		pointer-events: none;
// 	}
// }

// Default CDK overlay used by datatime picker to .5 alpha
.cdk-overlay-dark-backdrop {
	background-color: rgba(0, 0, 0, 0.5) !important;
	pointer-events: none !important;
}

// Blur Backgrounds
// @media (max-width: 1366px) {
// 	.modal.show {
// 		backdrop-filter: blur(5px) !important;
// 	}
// 	.cdk-overlay-backdrop-showing {
// 		backdrop-filter: blur(5px) !important;
// 	}
// }

// Date Picker Floating Title
#date-picker-floating-title {
	display: none;
}

body.dt-picker-open #date-picker-floating-title {
	display: none;
	z-index: 2500;
	padding: 5px 10px;
	border: 1px solid #fff;
	border-radius: 8px;
	background: #fff;
	position: fixed;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 600;
}

/* Delete Confirmation */

.delete-alert {
	margin-top: 15px;
}

.btn-confirm-delete {
	margin-left: 10px;
}
.confirm-delete-btn-wrap {
	margin-bottom: 10px;
}

.card-info-text {
	font-size: 80%;
	line-height: 1.3em !important;
	color: #818a91;
}

/* Miscellaneous */
.show-log-link {
	margin-top: 10px;
	font-size: 0.8em;
	font-weight: bolder;
}

.qbo-sync-error {
	color: firebrick;
	margin-left: 5px;
}

/* Blink Animation */
.blink {
	animation-duration: 1s;
	animation-name: blink;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: ease-in-out;
}
@keyframes blink {
	from {
		opacity: 1;
	}
	to {
		opacity: 0.2;
	}
}
/* Blink Animation */
.blink-half {
	animation-duration: 1.5s;
	animation-name: blink-half;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: ease-in-out;
}
@keyframes blink-half {
	from {
		opacity: 1;
	}
	to {
		opacity: 0.3;
	}
}

/* Text Blink Animation */
.text-blink {
	animation-duration: 1s;
	animation-name: text-blink;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: ease-in-out;
}
@keyframes text-blink {
	from {
		//  color: firebrick;
		background-color: #f0e4e4 !important;
	}
	to {
		//  color: #5e6064;
		background-color: #f9f9f9;
	}
}

// Row Pulser

@-webkit-keyframes row-pulse {
	from {
		background-color: rgb(245, 244, 214);
	}
	to {
		background-color: #fff;
	}
}
@-moz-keyframes row-pulse {
	from {
		background-color: rgb(245, 244, 214);
	}
	to {
		background-color: #fff;
	}
}
@-o-keyframes row-pulse {
	from {
		background-color: rgb(245, 244, 214);
	}
	to {
		background-color: #fff;
	}
}
@keyframes row-pulse {
	from {
		background-color: rgb(245, 244, 214);
	}
	to {
		background-color: #fff;
	}
}
.row-pulse {
	-webkit-animation: row-pulse 1s 3 ease-in-out; /* Safari 4+ */
	-moz-animation: row-pulse 1s 3 ease-in-out; /* Fx 5+ */
	-o-animation: row-pulse 1s 3 ease-in-out; /* Opera 12+ */
	animation: row-pulse 1s 3 ease-in-out; /* IE 10+ */
}

// General Element Pulse

@-webkit-keyframes element-pulse {
	from {
		background-color: #edf0f2;
	}
	to {
		background-color: #fff;
	}
}
@-moz-keyframes element-pulse {
	from {
		background-color: #edf0f2;
	}
	to {
		background-color: #fff;
	}
}
@-o-keyframes element-pulse {
	from {
		background-color: #edf0f2;
	}
	to {
		background-color: #fff;
	}
}
@keyframes element-pulse {
	from {
		background-color: #edf0f2;
	}
	to {
		background-color: #fff;
	}
}
.element-pulse {
	-webkit-animation: element-pulse 2s 1 ease; /* Safari 4+ */
	-moz-animation: element-pulse 2s 1 ease; /* Fx 5+ */
	-o-animation: element-pulse 2s 1 ease; /* Opera 12+ */
	animation: element-pulse 2s 1 ease; /* IE 10+ */
}

// General Chocolate Flash - Like pulse but flashes text-color to chocolate and back

@-webkit-keyframes chocolate-flash {
	from {
		color: chocolate;
	}
	to {
		color: #fff;
	}
}
@-moz-keyframes chocolate-flash {
	from {
		color: chocolate;
	}
	to {
		color: #fff;
	}
}
@-o-keyframes chocolate-flash {
	from {
		color: chocolate;
	}
	to {
		color: #fff;
	}
}
@keyframes chocolate-flash {
	from {
		color: chocolate;
	}
	to {
		color: #fff;
	}
}
.chocolate-flash {
	-webkit-animation: chocolate-flash 0.75s infinite; /* Safari 4+ */
	-moz-animation: chocolate-flash 0.75s infinite; /* Fx 5+ */
	-o-animation: chocolate-flash 0.75s infinite; /* Opera 12+ */
	animation: chocolate-flash 0.75s infinite; /* IE 10+ */
}

/* Navigation Highlights */

@keyframes card-highlighter {
	/* 0% { background-color: white } */
	40% {
		background-color: rgb(202, 221, 210);
	}
	/* 50%  {background-color: rgb(158, 187, 170);}
	75%  {background-color: rgb(131, 158, 142);}
	100%  {background-color: rgb(137, 165, 149);} */
}
.card-active {
	transition-timing-function: ease;
	animation-name: card-highlighter;
	animation-duration: 2s;
	animation-direction: alternate;
	/* animation-delay: .5s; */
}

/* Google Maps API */

.gMapJobAddress {
	margin-top: 5px;
}

.gmap-btn {
	appearance: button;
	background-color: #fff;
	border: 0;
	border-radius: 2px;
	box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
	cursor: pointer;
	margin: 10px;
	padding: 0 0.5em;
	height: 40px;
	width: 40px;
	font: normal normal normal 14px/1 FontAwesome;
	overflow: hidden;
}
.gmap-btn:hover {
	background: #ebebeb;
}
.gmap-btn-clusterer:before {
	content: '\f608'; // f058 e105 f140 f140 f2ce
	font-size: 24px;
	color: #dc4132;
}
.gmap-btn-clusterer-on:before {
	content: '\f608'; // f058 e105 f140 f140 f2ce
	font-size: 24px;
	color: #777;
}

.gm-style-iw {
	text-align: center;
}
.gm-style .gm-style-iw {
	width: 210px;
}

/* Bootstrap Tweaks */

.modal-footer > * {
	margin: 0px;
}

.btn:focus {
	box-shadow: none;
}

.badge-dark {
	background-color: #818a91; // #afb2b5;
}

/* Navagation Menu Tweaks */

.navbar-light .navbar-nav .open > .link-has-carrot:after {
	content: '\f0d7';
	font-family: FontAwesome;
	font-weight: normal;
	font-style: normal;
	margin: 0px 0px 0px 10px;
	text-decoration: none;
}

/* Bootstrap updates and fixes */

.valid-feedback.feedback-icon,
.invalid-feedback.feedback-icon {
	position: absolute;
	width: auto;
	bottom: 10px;
	right: 10px;
	margin-top: 0;
}
.container {
	width: 98%;
	// width: 98% !important;
}

.section-container {
	max-width: 98%;
}

@media (min-width: 1600px) {
	.section-container {
		width: 99.5%;
		max-width: 99.5%;
	}
}

.modal-header .close {
	padding: 0;
}
.modal-footer-left {
	position: absolute;
	left: 0;
}

#rrule-dates td:first-child {
	color: #ccc;
}
#rrule-dates td:first-child {
	text-align: right;
}
#rrule-dates td.cell-b {
	background: #eaeaea;
}
#rrule-dates td.cell-b {
	background: #f0f0f0;
}

/* clears the 'X' from Chrome search input*/
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

// list cards used in dialogs
.list-card {
	color: rgb(82, 94, 105);
}
.list-card.disabled {
	color: #a0a0a0;
	.badge {
		background-color: #a0a0a0;
	}
}
// DEPRECATED 2025-01-27 - Search and remove .list-card-no-shadow when cleaning this up
// .list-card.disabled:hover {
// 	color: #a0a0a0;
// 	box-shadow: inherit;
// }
// .list-card:hover {
// 	// color: #135c94;
// 	box-shadow: 0 1px 8px 0 rgb(82, 94, 105);
// }
// .list-card-no-shadow {
// 	box-shadow: none !important;
// }
// DEPRECATED 2025-01-27 - Search and remove .list-card-no-shadow when cleaning this up

.list-card-body {
	padding: 12px;
}
.list-card-header {
	cursor: pointer;
}
.lc-title {
	padding-top: 2px;
	font-weight: 500;
	line-height: 1.5rem;
	font-size: 1.1rem;
}
.lc-subtitle {
	color: gray;
	font-size: 0.9rem;
}
.lc-label-basic {
	font-weight: 600;
}
.lc-icon-wrap {
	font-size: 1.3rem;
	margin-left: 22px;
}
.lc-act-ico {
	cursor: pointer;
}
.lc-alert-block {
	margin: 16px 0px 0px 0px;
}
.lc-alert-title {
	color: black;
	font-weight: 500;
	margin-bottom: 12px;
}
.lc-alert-content {
	white-space: pre-wrap;
}
// Placeholders for future configuration
// .lc-flag-item {}
// .lc-flag-icon {}
// .lc-flag-label {}

// Copy into component scss to override global style
// .list-card-disabled {
// 	// opacity: 0.7;

// 	.lc-title,
// 	.lc-subtitle,
// 	.lc-description,
// 	.lc-act-ico,
// 	.lc-flag-item,
// 	.lc-flag-icon,
// 	.lc-flag-label {
// 		color: gray;
// 	}
// 	.fa-edit {
// 		color: green;
// 		opacity: 1;
// 	}
// }

.basic-card {
	padding: 12px;
	margin-bottom: 12px;
}
.card-expanded {
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.card-selected {
	background-color: #d7e2d7;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.card-gray {
	background-color: #f4f4f3;
}

// Confirm Delete label for dialogs

.confirm-delete-label {
	font-size: 1.3em;
	font-weight: 600;
	color: firebrick;
}
.delete-confirm-right-btn {
	min-width: 66px;
}
.delete-confirm-left-btn {
	min-width: 66px;
	margin-right: 12px;
}

.confirm-action-label {
	flex-shrink: 1;
	font-size: 1.3em;
	font-weight: 600;
	color: green;
	line-height: 1.2;
}
.confirm-action-sub-label {
	font-size: 1rem;
	font-weight: 400;
	color: gray;
}

// Confirm Delete block for cards

.delete-confirm-block {
	.delete-confirm-hr {
		margin: 20px 0px;
	}
	.delete-confirm-label {
		color: firebrick;
		font-size: 1.3rem;
		font-weight: 500;
	}
}

// Empty list in dialog

.list-empty {
	text-align: center;
	color: gray;
	border: 1px solid gray;
	border-radius: 5px;
	margin: 0px 20px;
	padding: 16px;
	font-size: 1.4em;
	font-weight: 500;
}

// Thin dialog btn
// Delete Dialog
.dialog-btn {
	padding: 0.15rem 0.5rem;
}

.alert-dialog {
	line-height: 1.2rem;
	background-color: #dadde0;
}

// Divider with centered title
.separator {
	display: flex;
	align-items: center;
	text-align: center;
}
.separator::before,
.separator::after {
	content: '';
	flex: 1;
	border-bottom: 1px solid #000;
}
.separator::before {
	margin-right: 0.25em;
}
.separator::after {
	margin-left: 0.25em;
}

// Fix for admin dialog opacity issue
app-header > .ui-dialog-wrapper,
.ui-widget-overlay {
	background-color: transparent !important;
	opacity: 1 !important;
	filter: none !important;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #bbb !important;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #bbb !important;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #bbb !important;
}

// Integration Styles

.int-log-divider {
	margin-top: 25px;
	margin-bottom: 25px;
}

.int-tooltip-block {
	margin-top: 20px;
	margin-bottom: 5px;
}

.int-card {
	max-width: 800px;
	margin: 0 auto;
	padding: 1.25rem;
}
.int-btn {
	margin-top: 5px;
	margin-bottom: 15px;
	width: 200px;
}
.int-dd-divider {
	margin: 10px 0px 20px 0px;
}
.sync-label {
	color: #666;
	font-weight: bold;
	font-size: 1.2em;
}
.sync-info-btn {
	font-size: 1.5em;
	float: right;
}
.int-log-btn {
	font-weight: bold;
}

/* Settings QBO */
.qbo-log-divider {
	margin-top: 25px;
	margin-bottom: 25px;
}

.qbo-tooltip-block {
	margin-top: 20px;
	margin-bottom: 5px;
}

// General tags
// General Tag Button
.tag-btn {
	cursor: pointer;
	background-color: #a5a8ad;
	// position: relative;
	// top: 2px;
}
.tag-btn:hover {
	background-color: #306ca0;
}

// Click-To-Call
.ctc-call-btn {
	cursor: pointer;
	background-color: #a5a8ad;
	// position: relative;
	// top: 2px;
}
.ctc-call-btn:hover {
	background-color: #306ca0;
}
.ctc-primary-contact {
	color: chocolate;
}
.ctc-emergency-contact {
	color: firebrick;
}
// Employee display active/inactive
.emp-active {
	color: green;
}
.emp-inactive {
	color: chocolate;
}

// Utilities
.no-text-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

//Begin - Pay Rate Section
.pay-rate-symbol {
	font-weight: 600;
	font-size: 1.3em;
	margin-right: 8px;
}
.pay-rate-input {
	width: 75px;
	height: 35px;
	display: inline-block;
}
/* Chrome, Safari, Edge, Opera */
.pay-rate-input::-webkit-outer-spin-button,
.pay-rate-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}

/* Firefox */
.pay-rate-input[type='number'] {
	-moz-appearance: textfield;
	appearance: textfield;
}
// End - Pay Rate Section

//Begin - Side Input Section
.side-input {
	width: 75px;
	height: 35px;
	display: inline-block;
}
/* Chrome, Safari, Edge, Opera */
.side-input::-webkit-outer-spin-button,
.side-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}

/* Firefox */
.side-input[type='number'] {
	-moz-appearance: textfield;
	appearance: textfield;
}
// End - Side Input Section

// BEGIN Global patches - always leave as last block in this style sheet

// Shifting caused by overlay in CDK
.cdk-global-scrollblock {
	// left: 0;
	position: initial;
}

// END Global patches - always leave as last block in this style sheet

// ETruncate with ellipsis
.trunc-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

// No double-click zoom
.no-zoom {
	touch-action: manipulation;
}

.pre-font {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

// Hide scrollbars
.hide-scrollbars {
	width: 100%;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
.hide-scrollbars::-webkit-scrollbar {
	display: none;
}

.overscroll-contain {
	overscroll-behavior: contain;
}

// CSS Animation issues on iOS
.ios-anime {
	--webkit-transform: translate3d(0, 0, 0);
}

// Public container for login/register/reset password
.public-container {
	padding: 15px 20px;
	max-width: 400px;
	margin: 0 auto;
}

// TTS Fade In/Out

.fade-out-in {
	animation: fadeOutIn 2s;
}

.fade-out-in-fast {
	animation: fadeOutIn 1s;
}

@keyframes fadeOutIn {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0.15;
	}
	100% {
		opacity: 1;
	}
}

// Global Loading Indicator

@keyframes global-loader-1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes global-loader-3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes global-loader-2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

@keyframes greenFade {
	0% {
		border-color: #d6d6d6;
		color: gray;
	}
	50% {
		border-color: green;
		color: green;
	}
	100% {
		border-color: #d6d6d6;
		color: #495057;
	}
}

.green-fade {
	animation: greenFade 1s forwards;
}

// Needs to be put on html tag
.hide-main-scrollbars {
	html {
		scrollbar-width: none;
	}
	body::-webkit-scrollbar {
		display: none;
	}
}

// Background colors - place after classes it modifies
.bg-chocolate {
	background-color: chocolate;
}
.bg-firebrick {
	background-color: firebrick;
}
.bg-green {
	background-color: green;
}
.bg-gray {
	background-color: lightgray;
}

// Buttons used as menu items in HUD

.btn-menu {
	display: block;
	width: 100%;
	cursor: pointer;
	text-align: center;
	background-color: transparent;
	background-image: none;
	border-radius: 0.375rem;
	user-select: none;
	padding: 0.25rem;
	font-size: 1rem;
	line-height: 1.5;
}
.btn-menu:focus {
	outline: none;
}

.btn-menu-primary {
	color: $btn-menu-color-primary;
	border: 1px solid $btn-menu-color-primary;
}
.btn-menu-primary:active {
	color: white;
	background-color: $btn-menu-color-primary;
}
.btn-menu-secondary {
	color: $btn-menu-color-secondary;
	border: 1px solid $btn-menu-color-secondary;
}
.btn-menu-secondary:active {
	color: white;
	background-color: $btn-menu-color-secondary;
}
.btn-menu-danger {
	color: $btn-menu-color-danger;
	border: 1px solid $btn-menu-color-danger;
}
.btn-menu-danger:active {
	color: white;
	background-color: $btn-menu-color-danger;
}

@media (min-width: 1200px) {
	.btn-menu-primary:hover {
		color: white;
		background-color: $btn-menu-color-primary;
	}
	.btn-menu-secondary:hover {
		color: white;
		background-color: $btn-menu-color-secondary;
	}
	.btn-menu-danger:hover {
		color: white;
		background-color: $btn-menu-color-danger;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
	appearance: textfield;
}

/* IntroJs Tweaks */
.introjs-tooltip {
	min-width: 300px;
	font-size: 18px;
	border-radius: 8px;
	// background-color: rgb(27 63 91 / 80%);
	background-color: rgb(27 78 118 / 85%);
	color: #ececec;
}

.introjs-dontShowAgain label {
	font-size: 18px;
	background-color: transparent;
	color: #ececec;
	position: relative;
	top: -2px;
}

.introjs-dontShowAgain input {
	width: 18px;
	height: 18px;
	margin-right: 4px;
}

.introjs-skipbutton {
	color: #ccc;
}

.introjs-skipbutton:hover {
	color: #fff;
}

.introjs-arrow.top,
.introjs-arrow.top-middle,
.introjs-arrow.top-right {
	border-color: transparent transparent rgb(27 78 118 / 85%);
}

// No records card
.no-records-card {
	padding: 10px;
	margin-bottom: 25px;
	text-align: center;
	font-size: 1.75rem;
	color: slategray;
}

.no-select {
	user-select: none;
}

//// DO NOT PUT OTHER STYLES BELOW THE PRINT STYLES //////

// General display and print classes

.no-display {
	display: none !important;
}

.nowrap {
	white-space: nowrap;
}
.no-page-break {
	page-break-inside: avoid;
}
.page-break-after {
	page-break-after: always;
}
.page-break-before {
	page-break-before: always;
	page-break-inside: avoid;
}
.print-only {
	display: none;
}

@media print {
	.noprint,
	.no-print * {
		visibility: hidden !important;
		display: none !important;
		// margin-top: 0px;
		// margin-bottom: 0px;
		// padding-top: 0px;
		// padding-bottom: 0px;
	}
	.print-only {
		display: block;
	}
}

//// DO NOT PUT OTHER STYLES BELOW THE PRINT STYLES //////

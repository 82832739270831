// Grid
.no-more-data {
	background: blue !important;
}

// Upper-left ANYTIME row label
.fc-timegrid-axis-cushion {
	font-size: small;
	text-align: center;
	font-variant: small-caps;
	line-height: 1.2em;
	font-weight: 500;
	padding: 4px 4px !important;
}

.fc-timeGridDay-button {
	margin-right: 16px !important;
}

.fc-next-button {
	margin-left: 16px !important;
}

.fc-col-header-cell {
	cursor: pointer !important;
}

@media (max-width: 321px) {
	.fc-toolbar-title {
		font-size: 1em !important;
	}
}
@media (max-width: 376px) {
	.fc-toolbar-title {
		font-size: 1.2em !important;
	}
}

// Events
.fc-event {
	cursor: pointer !important;
}
.fc-event-time {
	white-space: normal !important;
}
.fc-toolbar-title {
	color: slategray;
}
.fc-event-main-frame {
	padding: 0px 2px;
}

// Custom class for icons in events
.tts-fc-event-icon-box {
	padding: 4px;
	// border: 1px solid #fff;
	// border-radius: 8px;
}
.tts-fc-event-icon {
	cursor: pointer;
	color: #fff;
	font-size: 22px;
}
.tts-fc-event-icon:hover {
	color: #ddd;
}
.tts-fc-event-icon-assign {
	font-size: 20px;
}
.tts-fc-event-icon-split {
	font-size: 20px;
}

// Backgrounds
// https://www.magicpattern.design/tools/css-backgrounds

.fc-event-disabled {
	$titleColor: #151233;

	opacity: 0.7;
	background-size: 8px 8px !important;
	background-image: repeating-linear-gradient(-45deg, transparent 0, white 0.9px, #e5e5f7 0, #e5e5f7 50%) !important;
	// background-image: repeating-linear-gradient(-45deg, transparent 0, white 0.9px, #e5e5f7 0, #e5e5f7 50%); // Original
	// background-image: repeating-linear-gradient(-45deg, transparent 0, white 0.9px, #e5e5f7 10%, transparent 50%); // Dark
	// background-image: repeating-linear-gradient(-45deg, transparent 0, white 0.9px, transparent 50%); // Dark
	// border-width: 2px !important;

	.fc-event-title {
		color: $titleColor;
		font-weight: 500;
	}

	.fc-event-time,
	.tts-event-time {
		color: $titleColor;
		font-weight: 500;
	}
}

.fc-event-notify-disabled {
	opacity: 0.7;
	// $titleColor: #151233;

	// background-size: 2px 2px !important;
	// background-image: repeating-linear-gradient(-45deg, transparent 0, white 0.9px, #e5e5f7 0, #e5e5f7 50%) !important;

	// .fc-event-title {
	// 	color: $titleColor;
	// 	font-weight: 500;
	// }

	// .fc-event-time,
	// .tts-event-time {
	// 	color: $titleColor;
	// 	font-weight: 500;
	// }
}

.fc-event-timeoff-warn {
	background-color: #efefef !important;
	border: 1px solid red !important;
	color: red !important; // Added to support detail view
	.fc-event-title {
		color: red !important;
	}
}

.fc-event-timeoff-good {
	background-color: #efefef !important;
	border: 1px solid gray !important;
	color: gray !important; // Added to support detail view
	.fc-event-title {
		color: gray !important;
	}
}

// Column Header Dates
// a.fc-col-header-cell-cushion {
// 	color: #007bff;
// 	text-decoration: none;
// }

// All full calendar links
.fc.fc-theme-standard a {
	color: #007bff;
}

// Deprecated flash - use fade out in
.fc-os-event-flash {
	background-color: #efefef !important;
	.tts-event-time {
		color: red !important;
	}
	.fc-event-title {
		color: red !important;
	}
}

// Added after V6 update
.fc-daygrid-day-events {
	.fc-event {
		white-space: nowrap;
		font-size: var(--fc-small-font-size, 0.85em);
		margin: 2px 2px;
		border-radius: 3px;
	}
}

.urr-page-box {
	margin: 25px;
	text-align: left;
	// display: block;
}
.urr-report-wrapper {
	width: 100%;
	margin: auto;
}
.urr-block {
	padding-top: 25px;
}
.urr-card {
	padding: 12px;
	margin-top: 12px;
}
.urr-card-title {
	font-size: 1.4em;
	font-weight: 600;
	color: #a28643;
}
.urr-card-body {
	margin-top: 15px;
}
.urr-body-hr {
	margin: 25px 0px 25px 0px;
}
.urr-section {
	margin-top: 6px;
	margin-bottom: 6px;
}

// Images
.urr-img-box {
	width: 130px;
	border: 1px solid gray;
	border-radius: 8px;
	overflow: hidden;
	margin-bottom: 25px;
	cursor: pointer;
	background-color: black;
	color: white;
}

// Header
.urr-header-label {
	margin-right: 8px;
	display: inline-block;
}
.urr-header-value {
	color: gray;
	display: inline-block;
}

.urr-pfp-image {
	min-height: 100px;
	max-height: 126px;
	margin-left: 25px;
	border: 1px solid gray;
	border-radius: 4px;
	cursor: pointer;
}

// General
.urr-label {
	font-weight: bold;
	color: #135c94;
}
.urr-description {
	margin-top: 10px;
	line-height: 1.3em;
	color: slategray;
}

// Answer Block
.urr-answer-card {
	margin-top: 25px;
}
.urr-answer-label {
	line-height: 1.2em;
	display: inline;
	margin-right: 8px;
}
.urr-answer-description {
	color: #a28643;
}
.urr-answer-btn {
	width: 50px;
	text-align: center;
	font-weight: 500;
	padding: 0px 5px;
	margin-left: 25px;
	border: 1px solid lightgray;
	background-color: firebrick;
	color: white;
	border-radius: 5px;
}
.urr-answer-btn-sm {
	float: right;
	font-size: 0.7em;
	width: 35px;
	margin-left: 25px;
}

.urr-answer-yes {
	background-color: green;
}
.urr-answer-value {
	background-color: #135c94;
	width: unset;
	min-width: 50px;
}
.urr-answer-skip {
	background-color: gray;
}

.urr-sub-answer-block {
	margin-bottom: 10px;
}
.urr-sub-answer-label {
	line-height: 1em;
	font-weight: bold;
	color: gray;
}

.urr-show-link {
	font-weight: 400;
	top: -2px;
	position: relative;
	background-color: #545b62;
}
.urr-show-link:focus {
	box-shadow: none !important;
}
.urr-show-link:hover {
	color: white;
	background-color: #7e8286;
}

// Contact Block
.urr-contact-block {
	margin-top: 10px;
}
.urr-contact-label {
	font-weight: bold;
	color: gray;
}
.urr-contact-value {
	color: #a28643;
	font-weight: 500;
	line-height: 1.1em;
}
.urr-contact-notes {
	color: slategray;
}

// Employee Checklist blocks
.urr-ecl-instructions {
	color: gray;
	font-size: 1em;
	line-height: 1.1em;
	font-style: italic;
	margin-bottom: 20px;
}

@media (min-width: 1025px) {
	.urr-report-wrapper {
		width: 1024px;
	}
}

@media print {
	.urr-report-wrapper {
		width: 100%;
	}
}

.urr-card-expander {
	color: #0275d8;
	cursor: pointer;
}
.urr-card-expander-box {
	color: #0275d8;
	position: absolute;
	top: 0px;
	right: 0px;
	border-left: 1px solid #dfdfdf;
	border-bottom: 1px solid #dfdfdf;
	padding: 4px 6px 4px 8px;
	border-bottom-left-radius: 4px;
	cursor: pointer;
}

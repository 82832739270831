@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

// DT Responsive Fix Start - need to wrap contents of cell with the control in a dtr-control-content div
.dtr-control-content {
	display: inline-block;
	padding-right: 16px;
}

// Green and red circle chevrons
td.dtr-control:before {
	display: inline-block !important;
	vertical-align: top !important;
	margin-right: 5px !important;
	width: 16px !important;
	height: 16px !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
	color: #31b131; // green;
	font-family: 'Font Awesome 6 Pro'; /* Replace "Pro Regular" with the appropriate style prefix */
	font-weight: 900; /* Font weight for regular icons */
	content: '\f330'; /* Unicode value for the "angle-right" icon */
	-webkit-font-smoothing: antialiased;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th.dtr-control:before {
	color: #d33333; // firebrick;
	font-family: 'Font Awesome 6 Pro'; /* Replace "Pro Regular" with the appropriate style prefix */
	font-weight: 900; /* Font weight for regular icons */
	content: '\f32d'; /* Unicode value for the "angle-right" icon */
	-webkit-font-smoothing: antialiased;
}

// DT Responsive Fix END

.search-active {
	.dataTables_filter {
		color: chocolate;
	}
}
// Table progress indicator
.dataTables_processing {
	position: fixed !important;
	top: 50vh !important;
	left: 50% !important;
	background-color: #efefef;
	opacity: 0.9;
	border: 1px solid #efefef;
	border-radius: 16px;
	z-index: 1000;
}
.dataTables_processing > div:last-child > div {
	background: #135c94 !important;
}

// Page length
.dataTables_length {
	label {
		color: gray;
	}
	select {
		margin: 0px 6px;
	}
}

// Page length control
@media (min-width: 768px) {
	.dataTables_length {
		position: relative;
		top: 4px;
	}
}

#employeesTable > tbody > tr.child span.dtr-title {
	min-width: 100px;
}
#employeesTable > tbody > tr.child span.dtr-data {
	.emp-enroll-icon {
		margin-left: 32px;
	}
	.cell-map-block {
		margin-left: 34px;
	}
	.emp-onboard-icon,
	.emp-profile-icon {
		margin-left: 32px;
	}
	.emp-union-cb {
		margin-left: 30px;
	}
	.table-btn-files {
		margin-left: 14px;
	}
	.prop-toggle {
		margin-left: 22px;
	}
}

#userTable > tbody > tr.child span.dtr-title {
	min-width: 100px;
}

#contactTable > tbody > tr.child span.dtr-title {
	min-width: 85px;
}

#organizationTable > tbody > tr.child span.dtr-title {
	min-width: 100px;
}

#npTable > tbody > tr.child span.dtr-title {
	min-width: 90px;
}

#transactionsTable > tbody > tr.child {
	.trans-table-chkpt-rep-wrap {
		top: 0px;
	}
}

#stationTable > tbody > tr.child span.dtr-title {
	width: 100px;
}
#stationTable > tbody > tr.child span.dtr-data {
	.ip-lock-wrap {
		display: inline-block;
		margin-left: 14px;
	}
}
#payRatesTable > tbody > tr.child {
	span.dtr-title {
		min-width: 100px;
	}
	.prt-code-select {
		margin-top: 8px;
	}
}

.table-value-not-set {
	font-size: 0.9em;
	font-weight: bold;
	color: chocolate;
}

// Hide search filter input - place on table wrapper div
.dt-search-hide {
	input.search-field-input {
		display: none;
	}
}

// Bubble divider
.table-bubble-divider {
	font-size: 1.3em;
	position: relative;
	top: 4px;
	margin: 0px 8px;
}
.trans-table-chkpt-rep-wrap {
	position: relative;
	top: -4px;
}

// Trans Tabele Job/Schedule Wrapper
.trans-job-sched-wrap {
	min-width: 190px;
	max-width: 300px;
	font-size: 0.9em;
	white-space: normal;
	line-height: 1.1em;
	color: #1d6399;
	cursor: pointer;
}

// Field ellipsis
.display-trunc {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 200px;
}
/* Action Item Icons */

.action-btns-wrap {
	margin-left: 10px;
}
.action-icon {
	width: 30px;
}
.action-icon-disabled {
	// color: rgb(168, 167, 167) !important;
	color: #a8a7b3 !important;
	// cursor: default !important;
}
.action-icon-events-disabled {
	pointer-events: none;
	cursor: default;
}
// .gps-icon-box {
// 	margin-left: 5px;
// 	margin-right: 5px;
// }
.action-icon-gps-dist {
	color: rgb(99, 98, 104);
	font-size: 1.1em;
	// width: 15px;
}

.late-icon-mark {
	font-size: 1.15em;
}
.action-icon-gps-time {
	color: rgb(99, 98, 104);
	font-size: 1.1em;
	// width: 15px;
	// margin-left: 3px;
	// margin-right: 8px;
}
.emp-onboard-icon,
.emp-profile-icon {
	font-size: 22px;
	line-height: 16px;
	display: inline-block;
	margin-left: 16px;
	cursor: pointer;
}
.emp-enroll-icon {
	font-size: 20px;
	line-height: 16px;
	display: inline-block;
	margin-left: 18px;
}
.emp-union-cb {
	display: inline-block;
	margin-left: 14px;
}
.cell-icon-lg {
	font-size: 20px;
	display: inline-block;
	position: relative;
	top: 2px;
}

.action-icon-green {
	color: green;
}
.action-icon-blue {
	color: #0275d8;
}
.action-icon-run {
	color: #0275d8;
	font-size: 1.3em;
	cursor: pointer;
}
.action-icon-rep-last-period {
	font-size: 1.2em;
	color: #0275d8;
	cursor: pointer;
}
.action-icon-prefs {
	position: relative;
	top: 0px;
	font-size: 1.2em;
	width: 26px;
	color: #0275d8;
	cursor: pointer;
}
.action-icon-perms {
	position: relative;
	top: 2px;
	font-size: 1.3em;
	color: #0275d8;
	cursor: pointer;
}
.action-icon-rep-empty {
	font-size: 1.3em;
	cursor: pointer;
}
.action-icon-rep-view {
	color: green;
	font-size: 1.3em;
	cursor: pointer;
}
.action-icon-rep-dl {
	color: green;
	font-size: 1.3em;
	cursor: pointer;
}
.action-icon-voice {
	color: green;
	font-size: 1.3em;
	cursor: pointer;
	width: 15px;
}

.action-icon-app {
	color: rgb(99, 98, 104);
	font-size: 1.1em;
	width: 15px;
}

.action-icon-meta {
	color: rgb(99, 98, 104);
	font-size: 1.1em;
	width: 15px;
	margin-right: 6px;
}

.wrap-icon-inline {
	display: inline-block;
}

.action-icon-landline {
	color: rgb(99, 98, 104);
	font-size: 1.1em;
	width: 15px;
}

.action-icon-admin {
	color: rgb(99, 98, 104);
	font-size: 1.1em;
	width: 15px;
}

.action-icon-mobile {
	position: relative;
	top: 3px;
	color: rgb(99, 98, 104);
	font-size: 1.6em;
	width: 15px;
}

.action-icon-warning {
	color: firebrick;
	font-size: 1.2em;
	width: 15px;
}

.action-icon-none {
	width: 15px;
	color: #a8a7b3;
	font-size: 1.2em;
	// display: inline-block;
	// font-size: 1.6em;
	// color: red;
}

.details-icon-block {
	margin-top: 0px;
}
.details-icon-site {
	font-size: 1.1em;
	margin-right: 10px;
	position: relative;
	top: 1px;
}
.details-icon-job {
	font-size: 1.1em;
	margin-right: 11px;
	position: relative;
	top: 2px;
}
.details-icon-sched {
	position: relative;
	margin-right: 9px;
	top: 1px;
	font-size: 1.2em;
}
.details-icon-pay {
	position: relative;
	margin-right: 9px;
	top: 2px;
	font-size: 1.2em;
}
.details-icon-count {
	font-size: 1em;
	// margin-left: 11px;
	// position: relative;
	// top: 3px;
}
.details-icon-none {
	color: #96969e;
}

.trans-tag-icon {
	font-size: 1.2em;
	position: relative;
	top: 2px;
}

.action-icon-leftpad {
	margin-left: 10px;
}
.action-icon-rightpad {
	margin-right: 10px;
}

.action-icon-call {
	position: relative;
	color: #0275d8;
	font-size: 1.2em;
	cursor: pointer;
	width: 30px;
}

.action-icon-edit {
	position: relative;
	top: 1px;
	color: green;
	font-size: 1.4em;
	cursor: pointer;
}

.action-icon-delete {
	color: firebrick;
	font-size: 1.4em;
	cursor: pointer;
}

.action-icon-list {
	color: #a8a7b3;
	font-size: 1em;
	cursor: pointer;
	position: relative;
	top: 2px;
}

.action-icon-clone {
	position: relative;
	color: #0275d8;
	font-size: 1.1em;
	cursor: pointer;
}
.action-icon-reassign {
	color: #0275d8;
	font-size: 1.2em;
	position: relative;
	top: 2px;
}

.action-icon-view {
	position: relative;
	color: #a8a7b3;
	font-size: 1.1em;
	cursor: pointer;
}
.action-icon-target {
	position: relative;
	color: #a8a7b3;
	font-size: 1.1em;
	cursor: pointer;
}

.action-icon-tack {
	position: relative;
	// top: 1px;
	color: #a8a7b3;
	font-size: 1.2em;
	cursor: pointer;
}
.action-icon-emp-debug {
	font-size: 1.5em;
	color: #0275d8;
	// margin-left: -4px;
	position: relative;
	top: 2px;
	cursor: pointer;
}
.action-icon-job-code {
	margin-left: 10px;
	color: firebrick;
	font-size: 1.1em;
	display: inline-block;
}
.action-icon-alert {
	margin-left: 5px;
	color: firebrick;
	font-size: 1.1em;
	display: inline-block;
	cursor: pointer;
}
.action-icon-audit {
	color: #0275d8;
	font-size: 1.2em;
}
.action-icon-on {
	color: green;
}

// Table Icons

.table-icon-alert {
	margin-right: 5px;
	color: firebrick;
	font-size: 1.1em;
}
.job-icon-notify {
	font-size: 1.3em;
}
.job-icon-notify-in {
	font-size: 1.3em;
	margin-right: 8px;
}
.job-icon-notify-out {
	font-size: 1.3em;
	margin-left: 8px;
	margin-right: 4px;
}
.job-icon-notify-cp {
	font-size: 1.3em;
	margin-left: 8px;
}

// .tooltip-inner {
// 	white-space:pre;
// 	max-width:none;
// }

.user-icon-checkban {
	color: firebrick;
	margin-left: 20px;
	position: relative;
	top: 5px;
	font-size: 1.2em;
}
/* Table Buttons */

.table-pfp-img-sm {
	width: 100px;
	border: 1px solid gray;
	border-radius: 8px;
	margin-top: 4px;
}

.emp-score-box {
	cursor: pointer;
	display: inline-block;
	font-weight: 700;
	font-size: 0.8em;
	width: 45px;
	color: #536474;
	background-color: #ddd;
	border-radius: 15px;
	padding: 3px 0px 3px 0px;
	text-align: center;
}

.table-tag-icon {
	position: relative;
	top: 3px;
	color: green;
	font-size: 1.4rem;
	margin-left: 8px;
}
.tag-icon-expired {
	color: firebrick;
}

.table-tag {
	cursor: default;
	font-size: 0.8em;
	font-weight: bold;
	color: white;
	background-color: #537792;
	border-radius: 5px;
	text-align: center;
	display: inline-block;
	padding: 0px 6px;
	margin-right: 4px;
	white-space: normal;
}
.table-tag-sm {
	font-size: 12px;
}
.table-tag-success {
	background-color: green;
}
.table-tag-warn {
	background-color: chocolate;
}
.table-tag-danger {
	background-color: firebrick;
}
.table-tag-c2c-details {
	width: 80px;
	text-align: center;
}
.table-tag-c2c-blocked {
	background-color: chocolate;
	text-align: center;
}

.table-ext-id-normal {
	color: #537792;
	font-size: 0.8em;
	font-weight: bold;
	font-style: italic;
}

.table-tag-client {
	background-color: #537792;
	text-align: center;
}
.table-tag-vendor {
	background-color: olivedrab;
	text-align: center;
}
.tag-align-center {
	text-align: center;
}

.table-details {
	max-width: 250px;
	white-space: normal;
}

.table-modal-btn {
	font-weight: 700;
	cursor: pointer;
	height: 28px;
	width: 60px;
	color: #4c99db;
	background-color: #ddd;
	border: 1px solid lightslategray;
	border-radius: 15px;
	padding: 2px 2px 4px 2px;
	text-align: center;
}

.table-modal-btn-hl {
	font-weight: 700;
	cursor: pointer;
	width: 60px;
	color: #0275d8;
	background-color: #e9e2c0; // rgb(226, 194, 171); // rgb(218, 185, 185);
	border-color: lightslategray;
	border-radius: 15px;
	padding: 2px 2px 2px 2px;
}

.table-modal-btn-flag {
	font-weight: 700;
	cursor: pointer;
	width: 60px;
	color: white;
	background-color: firebrick; // rgb(226, 194, 171); // rgb(218, 185, 185);
	border-color: rgb(221, 177, 177);
	border-radius: 15px;
	padding: 2px 2px 2px 2px;
}

// .table-modal-btn-flag:hover {
// 	color: white;
// 	background-color: #0275d8;
// 	border-color: lightslategray;
// }
.table-modal-btn-match {
	color: white;
	background-color: chocolate;
	border-color: chocolate;
}

.table-modal-btn-match:hover,
.table-modal-btn-flag:hover,
.table-modal-btn:hover,
.table-modal-btn-hl:hover {
	color: white !important;
	background-color: #0275d8 !important;
}

.qbo-btn {
	width: 15px;
	font-weight: 700;
	padding: 3px 8px 3px 8px;
	border: 1px solid #ddd;
	border-radius: 5px;
	color: #0275d8;
	background-color: #ddd;
}
.qbo-btn:hover {
	color: white;
	background-color: #0275d8;
	border: 1px solid #0275d8;
}

// Checkboxes

.sched-enabled {
	font-size: 1.1em;
	cursor: pointer;
	margin-left: 15px;
}
.sched-day {
	font-size: 1.1em;
	cursor: pointer;
}
/* .qbo-btn:focus {
	color: #0275d8;
	background-color: #ddd;
} */

/* Icon for clearing search box in data tables - also a class for use on multiple tables on same page */

#clear-search-icon {
	margin-left: -30px;
	margin-right: 10px;
	// margin-left: 10px;
	color: #ccc;
	font-size: 1.2em;
	position: relative;
	top: 2px;
}

#clear-search-icon:hover {
	cursor: pointer;
}

// .clear-search-icon {
// 	margin-left: 10px;
// 	color: #ccc;
// 	font-size: 1.2em;
// }

// .clear-search-icon:hover {
// 	cursor: pointer;
// }

.search-field-input {
	width: 250px;
	padding-right: 35px !important;
	border: 2px solid #ccc !important;
	border-radius: 5px !important;
	margin-bottom: 8px;
	appearance: none !important;
}

/* DataTables Global Styling */
table.dataTable.stripe tbody tr {
	vertical-align: top;
}

// table.dataTable {
// 	border: 1px solid #cccccc !important;
// 	border-radius: 8px !important;
// }

/* Modal Dialog for Date Picker */

/* DataTable Tweaks */

// Remove vertical centerting for responsive icon
// td.dtr-control {
// 	padding-left: 35px !important;
// }
.dataTables_length {
	padding-top: 5px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	box-sizing: border-box;
	display: inline-block;
	min-width: 1.5em;
	padding: 3px;
	margin-left: 2px;
	text-align: center;
	text-decoration: none !important;
	cursor: pointer;
	color: #333333 !important;
	border: 1px solid transparent;
	border-radius: 5px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
	outline: none;
	background-color: #2b2b2b;
	background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
	box-shadow: inset 0 0 3px #111;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	color: white !important;
	border: 1px solid #0275d8;
	background-color: #585858;
	background: #0275d8;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
	color: #333333 !important;
	border: 1px solid #979797;
	background-color: white;
	background: white !important;
	/* background: linear-gradient(to bottom, white 0%, gainsboro 100%); */
}

// Table Notes Cell
// .table-note-block {
// 	line-height: 1.3em;
// 	margin-bottom: 6px;
// 	border: 1px solid lightslategray;
// 	padding: 4px;
// 	border-radius: 8px;
// 	min-width: 250px;
// }
.table-note-label {
	font-weight: bold;
	color: #135c94;
}
// .table-note-copy {
// 	white-space: normal;
// }

.trans-job-addr {
	width: 100%;
	white-space: pre-wrap;
}

.table-note-block,
.trans-note-block {
	white-space: pre-wrap;
	font-size: 0.9em;
	line-height: 1.3em;
	margin-bottom: 6px;
	border: 1px solid lightslategray;
	padding: 4px;
	border-radius: 8px;
	min-width: 250px;
	max-width: 600px;
	max-height: 130px;
	overflow: hidden;
	overflow-y: auto;
	background-color: #efefef;
}

.trans-note-link-badge {
	display: inline-block;
	font-size: 0.9rem;
	color: white;
	background-color: #4b88b6;
	position: relative;
	margin-bottom: 4px;
	top: 4px;
	cursor: pointer;
}
.trans-note-link-badge:hover {
	background-color: #1d6399;
}
.trans-note-link-edit-badge {
	background-color: #c3a259;
}
.trans-note-link-edit-badge:hover {
	background-color: #a38544;
}

.trans-break-flag {
	text-align: center;
	margin: 4px 0px;
	padding: 4px 4px;
	font-size: 13px;
	font-weight: bold;
	line-height: 1.3em;
	border-radius: 4px;
	background-color: #2a991c;
	color: white;
}
.trans-checkin-count {
	text-align: center;
	margin: 4px 0px;
	padding: 2px 4px;
	font-size: 12px;
	font-variant: small-caps;
	font-weight: bold;
	border-radius: 4px;
	// background-color: #1d6399;
	background-color: #bc900e;

	color: white;
}
.trans-sched-flag {
	text-align: center;
	margin: 4px 0px;
	padding: 2px 4px;
	font-size: 12px;
	font-variant: small-caps;
	font-weight: bold;
	border-radius: 4px;
	background-color: #1d6399;
	color: white;
}
.tsf-gray {
	background-color: #888f95;
}
.bg-flag-red {
	background-color: firebrick;
}
.trans-highlight-flag {
	display: inline-block;
	padding: 0px 4px 1px 4px;
	margin: 1px 0px;
	// border: 1px solid rgba(255,255,255,.5);
	background: firebrick;
	border-radius: 4px;
	color: white !important;
}

.trans-highlight-warn {
	display: inline-block;
	padding: 0px 4px 1px 4px;
	margin: 1px 0px;
	// border: 1px solid rgba(255,255,255,.5);
	background: chocolate;
	border-radius: 4px;
	color: white;
}

.trans-dow-wrong-day {
	display: inline-block;
	padding: 2px 4px;
	margin-top: 4px;
	border: 1px solid firebrick;
	background-color: firebrick;
	border-radius: 4px;
	color: white;
}

/* DataTables Disabled Row Styles */
tr.emp-disabled {
	color: #999;
	.table-tag {
		opacity: 0.7 !important;
	}
}
tr.job-disabled {
	color: #999;
	.table-tag {
		opacity: 0.7 !important;
	}
}
tr.vac-disabled {
	color: #999;
	.table-tag {
		opacity: 0.7 !important;
	}
}
tr.rep-history-disabled {
	color: #aaa;
}

//////////////////////////////
// Transaction Highlighting //
//////////////////////////////

table.dataTable.stripe > tbody > tr.trans-hours-missing > * {
	box-shadow: inset 0 0 0 9999px rgb(240, 228, 228);
	border-bottom: 1px solid white !important;
}

table.dataTable.stripe > tbody > tr.trans-hours-ongoing > * {
	box-shadow: inset 0 0 0 9999px #c2e1c2;
	border-bottom: 1px solid white !important;
}
table.dataTable.stripe > tbody > tr.trans-hours-pending > * {
	box-shadow: inset 0 0 0 9999px #d5e9d5;
	border-bottom: 1px solid white !important;
}

table.dataTable.stripe > tbody > tr.trans-hours-paused > * {
	box-shadow: inset 0 0 0 9999px #1795e030;
	border-bottom: 1px solid white !important;
}

table.dataTable.stripe > tbody > tr.row-has-error > * {
	box-shadow: inset 0 0 0 9999px rgb(240, 228, 228);
	border-bottom: 1px solid white !important;
}

tr.trans-modified {
	background-color: rgb(245, 244, 214) !important;
	border-bottom: 1px solid white !important;
}

tr.row-flag-green {
	background-color: rgb(194, 225, 194) !important;
	border-bottom: 1px solid white !important;
}

tr.row-flag-yellow {
	background-color: rgb(245, 244, 214) !important;
	border-bottom: 1px solid white !important;
}

tr.row-flag-red {
	background-color: rgb(240, 228, 228) !important;
	border-bottom: 1px solid white !important;
}

tr.trans-has-exception {
	background-color: rgb(240, 228, 228) !important;
	border-bottom: 1px solid white !important;
}

tr.row-blink-red {
	background-color: rgb(240, 228, 228) !important;
	border-bottom: 1px solid white !important;
}

// Used for tables with toggleable properties

.prop-toggle {
	cursor: pointer;
	padding: 0px 5px 0px 5px;
	font-size: 1.1em;
}

// Data Tables column headers
table.dataTable thead {
	font-size: 1em;
	padding: 10px 12px;
	line-height: 1.1em;
	// color: #135c94;
	color: #466c8a;
}

.check-in-ratio-badge {
	cursor: default;
	margin: -2px 0px 0px 8px;
	font-size: 0.8em;
	// line-height: .8em;
	color: #eee;
	background-color: #135c94 !important;
	display: inline;
}
.check-in-ratio {
	margin-left: 5px;
	font-weight: bold;
	font-size: 0.9em;
	color: #135c94;
}

// Audit Table
.alt-op-delete {
	font-variant: small-caps;
	font-weight: bold;
	color: firebrick;
}
.alt-op-insert {
	font-variant: small-caps;
	font-weight: bold;
	color: green;
}
.alt-op-update {
	font-variant: small-caps;
	font-weight: bold;
	color: #466c8a;
}
.alt-op-unknown {
	font-variant: small-caps;
	font-weight: bold;
	color: slategray;
	white-space: normal;
}
.alt-table-icon {
	cursor: pointer;
	position: relative;
	font-size: 1.4em;
	color: #0275d5;
}
.alt-table-icon:hover {
	color: #014c8c;
}
.alt-table-i-show {
	font-size: 1.6em;
	top: 1px;
}
.alt-table-i-history {
	margin-left: 15px;
}
.alt-rec-count {
	color: #014c8c;
	margin-left: 8px;
	font-variant: small-caps;
	font-weight: bold;
}

.custom-field {
	color: #537792;
	font-weight: bold;
	font-style: italic;
}
.valid-ext-id {
	color: #537792;
	font-size: 0.8em;
	font-weight: bold;
	font-style: italic;
}

// Cell address block
.cell-addr-block {
	max-width: 200px;
}
.cell-addr-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.cell-map-block {
	display: inline-block;
	margin-left: 8px;
}
// Link Table Dialogs

.ldc-list {
	margin-top: 10px;
}
.ldc-card {
	padding: 12px;
	margin-bottom: 15px;
}
.ldc-card-expanded {
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.ldc-title {
	font-weight: 600;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #135c94;
}
.ldc-sub-title {
	font-size: 0.8em;
	font-variant: small-caps;
	font-weight: 400;
	color: gray;
}
.ldc-card-note {
	color: slategray;
	font-style: italic;
}
.ldc-act-ico-wrap {
	float: right;
	font-size: 1.3rem;
	margin-left: 16px;
	cursor: pointer;
}
.ldc-act-ico {
	margin-left: 12px;
	width: 22px;
}

// Table Columns and misc table styles
.table-name {
	white-space: normal;
	line-height: 1.3em;
	min-width: 100px;
	max-width: 160px;
	word-wrap: break-word;
}
.table-text {
	white-space: normal;
	line-height: 1.3em;
	min-width: 100px;
	max-width: 160px;
	word-wrap: break-word;
}
.table-email {
	min-width: 250px;
	max-width: 320px;
	word-break: break-all;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: normal;
}

// Table Footnotes

.table-footnote {
	margin: 12px 0 8px 0;
	text-align: left;
	line-height: 1.3em;
	color: #818a91;
	background-color: #f9f9f9;
	border: 1px solid #b2b2b2;
	border-radius: 4px;
	padding: 8px;
}

@media (min-width: 768px) {
	.table-footnote {
		// margin-top: -30px;
		text-align: left;
	}
}

.table-ico-sync-lock {
	color: chocolate;
	margin-left: 10px;
	font-size: 1.2rem;
	position: relative;
	top: 4px;
}

// Station Table
.table-setup-code {
	font-family: 'Roboto Mono', monospace;
}

.cell-text-break {
	overflow-wrap: break-word;
	white-space: normal;
}

.cell-c2c {
	min-width: 156px;
	max-width: 250px;
	word-wrap: break-word;
	white-space: normal;
}

// Table Footer Styles
.table-footer-block {
	width: 100%;
	max-width: 400px;
	margin: 12px 0px 0px 0px;
}
@media (min-width: 500px) {
	.table-footer-block {
		max-width: 350px;
		margin: 12px 8px 0px 0px;
	}
}

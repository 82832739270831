// Scale transaction map dialogs for larger screen

.map-dialog {
	.map-box {
		width: 100%;
		height: 400px;
	}
	.map-box-content {
		width: 100%;
		height: 400px;
	}

	/* Mobile devices (portrait) */
	@media (max-width: 599px) {
		/* Styles for small screens in portrait */
		.map-box {
			width: 100%;
			height: calc(100dvh - 180px);
		}
		.map-box-content {
			width: 100%;
			height: calc(100dvh - 180px);
		}
	}

	// Desktop
	@media (min-width: 1200px) {
		.modal-dialog {
			max-width: 800px;
		}
		.map-box {
			width: 100%;
			height: 600px;
		}
	}

	// Desktop Large
	@media (min-width: 1400px) {
		.modal-dialog {
			max-width: 1000px;
		}
		.map-box {
			width: 100%;
			height: 800px;
		}
	}

	// iPad Mini Portrait
	@media (width: 768px) and (height: 1024px) and (orientation: portrait) {
		.modal-dialog {
			max-width: 600px;
		}
		.map-box {
			width: 100%;
			height: 760px;
		}
	}
	// iPad Mini Landscape
	@media (height: 768px) and (width: 1024px) and (orientation: landscape) {
		.modal-dialog {
			max-width: 800px;
		}
		.map-box {
			width: 100%;
			height: 480px;
		}
	}

	// iPad Air Portrait
	@media (width: 820px) and (height: 1180px) and (orientation: portrait) {
		.modal-dialog {
			max-width: 700px;
		}
		.map-box {
			width: 100%;
			height: 900px;
		}
	}
	// iPad Air Landscape
	@media (height: 820px) and (width: 1180px) and (orientation: landscape) {
		.modal-dialog {
			max-width: 900px;
		}
		.map-box {
			width: 100%;
			height: 550px;
		}
	}

	// iPad Pro Portrait
	@media (width: 1024px) and (height: 1366px) and (orientation: portrait) {
		.modal-dialog {
			max-width: 900px;
		}
		.map-box {
			width: 100%;
			height: 1000px;
		}
	}
	// iPad Pro Landscape
	@media (height: 1024px) and (width: 1366px) and (orientation: landscape) {
		.modal-dialog {
			max-width: 1000px;
		}
		.map-box {
			width: 100%;
			height: 700px;
		}
	}

	// Other Devices//

	// Surface Pro 7 - Only need portrait
	@media (width: 912px) and (height: 1368px) and (orientation: portrait) {
		.modal-dialog {
			max-width: 760px;
		}
		.map-box {
			width: 100%;
			height: 800px;
		}
	}
}

.info-inout-title {
	color: white;
	background-color: #353535;
	border: 1px solid #090909;
	border-radius: 4px;
	font-weight: bold;
	margin-bottom: 10px;
}

.info-job-title {
	color: white;
	background-color: #5bab43;
	border: 1px solid #5fb644;
	border-radius: 4px;
	font-weight: bold;
	margin-bottom: 10px;
}
.info-job-addr {
	margin: 8px 0px 12px 0px;
}

.info-checkpoint-title {
	color: white;
	background-color: #039aff;
	border: 1px solid #039aff;
	border-radius: 4px;
	font-weight: bold;
	margin-bottom: 10px;
}

.no-gps-info-window {
	.info-checkpoint-title {
		min-width: 180px;
	}
}

// Open Transactions Map

.otm-info-name {
	color: #002e99;
	font-weight: bold;
}
.otm-info-job {
	margin-top: 10px;
}
.otm-info-company {
	margin-top: 10px;
}
.otm-info-start-time {
	margin-top: 8px;
}
.otm-info-checkin-type {
	margin-top: 10px;
	font-weight: bold;
	color: gray;
}
.otm-info-status {
	margin-top: 6px;
	font-weight: bold;
}
.otm-no-gps {
	color: chocolate;
	font-weight: bold;
	margin-top: 6px;
}

// Map info window content
.map-iw-content {
	width: 180px !important;
}
@media (min-width: 768px) {
	.map-iw-content {
		padding: 0px 0px 0px 6px;
	}
}

// Hide Info window scrollbards
.gm-style-iw-d {
	width: 100%;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
.gm-style-iw-d::-webkit-scrollbar {
	display: none;
}

// Map info window footer
.map-iw-footer {
	margin: 5px 0px 10px 0px;
	color: slategray;
	font-weight: bold;
	white-space: nowrap;
}

// Hide the info window close icon
.gm-style-iw > button {
	display: none !important;
	visibility: hidden !important;
	opacity: 0 !important;
}

// This prevents the google maps info window from having scrollbars because of the content

.gm-style-iw-d {
	overflow: none !important;
	max-height: 226px !important;
}

// Remove weird blue border from map
.gm-style > div:nth-child(4) {
	border: unset !important;
}
// #transactionMapModal > div > div > div:nth-child(2) > div > div > div:nth-child(4) {
// 	border: unset !important;
// }

.timeline-highlight {
	background-color: #a78d4e !important;
}

// Image scroll snapper
.map-img-scroll-wrapper {
	width: 180px;
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	scroll-snap-type: x mandatory;
	display: flex;
}

.map-img-scroll-item {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
	width: 100%;
	scroll-snap-align: center;
	box-sizing: border-box;
	padding: 10px;
}

.map-img-scroll-item {
	img {
		height: auto;
		max-height: 150px;
		max-width: 100%;
		display: block;
		margin: 0 auto;
		border-radius: 4px;
	}
}

.map-scroll-prev-icon,
.map-scroll-next-icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-size: 24px;
	cursor: pointer;
	color: lightgray; /* Adjust the color as needed */
}

.map-scroll-prev-icon {
	left: 0;
	margin-left: 10px;
}
.map-scroll-next-icon {
	right: 0;
	margin-right: 10px;
}

.map-scroll-prev-visible {
	.map-scroll-prev-icon {
		display: block;
		color: #135c94;
	}
}

.map-scroll-next-visible {
	.map-scroll-next-icon {
		display: block;
		color: #135c94;
	}
}

.map-img-scroll-wrapper:hover .prev-icon,
.map-img-scroll-wrapper:hover .next-icon {
	display: block;
}

// Hide close button
.gm-style-iw-chr {
	button[title='Close'] {
		display: none !important;
	}
}

/*!
 * Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
.fa.fa-glass {
  --fa: "\f000"; }

.fa.fa-envelope-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-envelope-o {
  --fa: "\f0e0"; }

.fa.fa-star-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-star-o {
  --fa: "\f005"; }

.fa.fa-remove {
  --fa: "\f00d"; }

.fa.fa-close {
  --fa: "\f00d"; }

.fa.fa-gear {
  --fa: "\f013"; }

.fa.fa-trash-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-trash-o {
  --fa: "\f2ed"; }

.fa.fa-home {
  --fa: "\f015"; }

.fa.fa-file-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-o {
  --fa: "\f15b"; }

.fa.fa-clock-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-clock-o {
  --fa: "\f017"; }

.fa.fa-arrow-circle-o-down {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-arrow-circle-o-down {
  --fa: "\f358"; }

.fa.fa-arrow-circle-o-up {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-arrow-circle-o-up {
  --fa: "\f35b"; }

.fa.fa-play-circle-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-play-circle-o {
  --fa: "\f144"; }

.fa.fa-repeat {
  --fa: "\f01e"; }

.fa.fa-rotate-right {
  --fa: "\f01e"; }

.fa.fa-refresh {
  --fa: "\f021"; }

.fa.fa-list-alt {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-list-alt {
  --fa: "\f022"; }

.fa.fa-dedent {
  --fa: "\f03b"; }

.fa.fa-video-camera {
  --fa: "\f03d"; }

.fa.fa-picture-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-picture-o {
  --fa: "\f03e"; }

.fa.fa-photo {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-photo {
  --fa: "\f03e"; }

.fa.fa-image {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-image {
  --fa: "\f03e"; }

.fa.fa-map-marker {
  --fa: "\f3c5"; }

.fa.fa-pencil-square-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-pencil-square-o {
  --fa: "\f044"; }

.fa.fa-edit {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-edit {
  --fa: "\f044"; }

.fa.fa-share-square-o {
  --fa: "\f14d"; }

.fa.fa-check-square-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-check-square-o {
  --fa: "\f14a"; }

.fa.fa-arrows {
  --fa: "\f0b2"; }

.fa.fa-times-circle-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-times-circle-o {
  --fa: "\f057"; }

.fa.fa-check-circle-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-check-circle-o {
  --fa: "\f058"; }

.fa.fa-mail-forward {
  --fa: "\f064"; }

.fa.fa-expand {
  --fa: "\f424"; }

.fa.fa-compress {
  --fa: "\f422"; }

.fa.fa-eye {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-eye-slash {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-warning {
  --fa: "\f071"; }

.fa.fa-calendar {
  --fa: "\f073"; }

.fa.fa-arrows-v {
  --fa: "\f338"; }

.fa.fa-arrows-h {
  --fa: "\f337"; }

.fa.fa-bar-chart {
  --fa: "\e0e3"; }

.fa.fa-bar-chart-o {
  --fa: "\e0e3"; }

.fa.fa-twitter-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-twitter-square {
  --fa: "\f081"; }

.fa.fa-facebook-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-facebook-square {
  --fa: "\f082"; }

.fa.fa-gears {
  --fa: "\f085"; }

.fa.fa-thumbs-o-up {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-thumbs-o-up {
  --fa: "\f164"; }

.fa.fa-thumbs-o-down {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-thumbs-o-down {
  --fa: "\f165"; }

.fa.fa-heart-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-heart-o {
  --fa: "\f004"; }

.fa.fa-sign-out {
  --fa: "\f2f5"; }

.fa.fa-linkedin-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-linkedin-square {
  --fa: "\f08c"; }

.fa.fa-thumb-tack {
  --fa: "\f08d"; }

.fa.fa-external-link {
  --fa: "\f35d"; }

.fa.fa-sign-in {
  --fa: "\f2f6"; }

.fa.fa-github-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-github-square {
  --fa: "\f092"; }

.fa.fa-lemon-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-lemon-o {
  --fa: "\f094"; }

.fa.fa-square-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-square-o {
  --fa: "\f0c8"; }

.fa.fa-bookmark-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-bookmark-o {
  --fa: "\f02e"; }

.fa.fa-twitter {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-facebook {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-facebook {
  --fa: "\f39e"; }

.fa.fa-facebook-f {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-facebook-f {
  --fa: "\f39e"; }

.fa.fa-github {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-credit-card {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-feed {
  --fa: "\f09e"; }

.fa.fa-hdd-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hdd-o {
  --fa: "\f0a0"; }

.fa.fa-hand-o-right {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-o-right {
  --fa: "\f0a4"; }

.fa.fa-hand-o-left {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-o-left {
  --fa: "\f0a5"; }

.fa.fa-hand-o-up {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-o-up {
  --fa: "\f0a6"; }

.fa.fa-hand-o-down {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-o-down {
  --fa: "\f0a7"; }

.fa.fa-globe {
  --fa: "\f57d"; }

.fa.fa-tasks {
  --fa: "\f828"; }

.fa.fa-arrows-alt {
  --fa: "\f31e"; }

.fa.fa-group {
  --fa: "\f0c0"; }

.fa.fa-chain {
  --fa: "\f0c1"; }

.fa.fa-cut {
  --fa: "\f0c4"; }

.fa.fa-files-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-files-o {
  --fa: "\f0c5"; }

.fa.fa-floppy-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-floppy-o {
  --fa: "\f0c7"; }

.fa.fa-save {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-save {
  --fa: "\f0c7"; }

.fa.fa-navicon {
  --fa: "\f0c9"; }

.fa.fa-reorder {
  --fa: "\f0c9"; }

.fa.fa-magic {
  --fa: "\e2ca"; }

.fa.fa-pinterest {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-pinterest-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-pinterest-square {
  --fa: "\f0d3"; }

.fa.fa-google-plus-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-google-plus-square {
  --fa: "\f0d4"; }

.fa.fa-google-plus {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-google-plus {
  --fa: "\f0d5"; }

.fa.fa-money {
  --fa: "\f3d1"; }

.fa.fa-unsorted {
  --fa: "\f0dc"; }

.fa.fa-sort-desc {
  --fa: "\f0dd"; }

.fa.fa-sort-asc {
  --fa: "\f0de"; }

.fa.fa-linkedin {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-linkedin {
  --fa: "\f0e1"; }

.fa.fa-rotate-left {
  --fa: "\f0e2"; }

.fa.fa-legal {
  --fa: "\f0e3"; }

.fa.fa-tachometer {
  --fa: "\f625"; }

.fa.fa-dashboard {
  --fa: "\f625"; }

.fa.fa-comment-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-comment-o {
  --fa: "\f075"; }

.fa.fa-comments-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-comments-o {
  --fa: "\f086"; }

.fa.fa-flash {
  --fa: "\f0e7"; }

.fa.fa-clipboard {
  --fa: "\f0ea"; }

.fa.fa-lightbulb-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-lightbulb-o {
  --fa: "\f0eb"; }

.fa.fa-exchange {
  --fa: "\f362"; }

.fa.fa-cloud-download {
  --fa: "\f0ed"; }

.fa.fa-cloud-upload {
  --fa: "\f0ee"; }

.fa.fa-bell-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-bell-o {
  --fa: "\f0f3"; }

.fa.fa-cutlery {
  --fa: "\f2e7"; }

.fa.fa-file-text-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-text-o {
  --fa: "\f15c"; }

.fa.fa-building-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-building-o {
  --fa: "\f1ad"; }

.fa.fa-hospital-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hospital-o {
  --fa: "\f0f8"; }

.fa.fa-tablet {
  --fa: "\f3fa"; }

.fa.fa-mobile {
  --fa: "\f3cd"; }

.fa.fa-mobile-phone {
  --fa: "\f3cd"; }

.fa.fa-circle-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-circle-o {
  --fa: "\f111"; }

.fa.fa-mail-reply {
  --fa: "\f3e5"; }

.fa.fa-github-alt {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-folder-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-folder-o {
  --fa: "\f07b"; }

.fa.fa-folder-open-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-folder-open-o {
  --fa: "\f07c"; }

.fa.fa-smile-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-smile-o {
  --fa: "\f118"; }

.fa.fa-frown-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-frown-o {
  --fa: "\f119"; }

.fa.fa-meh-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-meh-o {
  --fa: "\f11a"; }

.fa.fa-keyboard-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-keyboard-o {
  --fa: "\f11c"; }

.fa.fa-flag-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-flag-o {
  --fa: "\f024"; }

.fa.fa-mail-reply-all {
  --fa: "\f122"; }

.fa.fa-star-half-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-star-half-o {
  --fa: "\f5c0"; }

.fa.fa-star-half-empty {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-star-half-empty {
  --fa: "\f5c0"; }

.fa.fa-star-half-full {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-star-half-full {
  --fa: "\f5c0"; }

.fa.fa-code-fork {
  --fa: "\f126"; }

.fa.fa-chain-broken {
  --fa: "\f127"; }

.fa.fa-unlink {
  --fa: "\f127"; }

.fa.fa-calendar-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-calendar-o {
  --fa: "\f133"; }

.fa.fa-maxcdn {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-html5 {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-css3 {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-unlock-alt {
  --fa: "\f09c"; }

.fa.fa-minus-square-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-minus-square-o {
  --fa: "\f146"; }

.fa.fa-level-up {
  --fa: "\f3bf"; }

.fa.fa-level-down {
  --fa: "\f3be"; }

.fa.fa-pencil-square {
  --fa: "\f14b"; }

.fa.fa-external-link-square {
  --fa: "\f360"; }

.fa.fa-compass {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-caret-square-o-down {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-caret-square-o-down {
  --fa: "\f150"; }

.fa.fa-toggle-down {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-toggle-down {
  --fa: "\f150"; }

.fa.fa-caret-square-o-up {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-caret-square-o-up {
  --fa: "\f151"; }

.fa.fa-toggle-up {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-toggle-up {
  --fa: "\f151"; }

.fa.fa-caret-square-o-right {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-caret-square-o-right {
  --fa: "\f152"; }

.fa.fa-toggle-right {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-toggle-right {
  --fa: "\f152"; }

.fa.fa-eur {
  --fa: "\f153"; }

.fa.fa-euro {
  --fa: "\f153"; }

.fa.fa-gbp {
  --fa: "\f154"; }

.fa.fa-usd {
  --fa: "\24"; }

.fa.fa-dollar {
  --fa: "\24"; }

.fa.fa-inr {
  --fa: "\e1bc"; }

.fa.fa-rupee {
  --fa: "\e1bc"; }

.fa.fa-jpy {
  --fa: "\f157"; }

.fa.fa-cny {
  --fa: "\f157"; }

.fa.fa-rmb {
  --fa: "\f157"; }

.fa.fa-yen {
  --fa: "\f157"; }

.fa.fa-rub {
  --fa: "\f158"; }

.fa.fa-ruble {
  --fa: "\f158"; }

.fa.fa-rouble {
  --fa: "\f158"; }

.fa.fa-krw {
  --fa: "\f159"; }

.fa.fa-won {
  --fa: "\f159"; }

.fa.fa-btc {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-bitcoin {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-bitcoin {
  --fa: "\f15a"; }

.fa.fa-file-text {
  --fa: "\f15c"; }

.fa.fa-sort-alpha-asc {
  --fa: "\f15d"; }

.fa.fa-sort-alpha-desc {
  --fa: "\f881"; }

.fa.fa-sort-amount-asc {
  --fa: "\f884"; }

.fa.fa-sort-amount-desc {
  --fa: "\f160"; }

.fa.fa-sort-numeric-asc {
  --fa: "\f162"; }

.fa.fa-sort-numeric-desc {
  --fa: "\f886"; }

.fa.fa-youtube-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-youtube-square {
  --fa: "\f431"; }

.fa.fa-youtube {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-xing {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-xing-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-xing-square {
  --fa: "\f169"; }

.fa.fa-youtube-play {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-youtube-play {
  --fa: "\f167"; }

.fa.fa-dropbox {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-stack-overflow {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-instagram {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-flickr {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-adn {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-bitbucket {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-bitbucket-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-bitbucket-square {
  --fa: "\f171"; }

.fa.fa-tumblr {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-tumblr-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-tumblr-square {
  --fa: "\f174"; }

.fa.fa-long-arrow-down {
  --fa: "\f309"; }

.fa.fa-long-arrow-up {
  --fa: "\f30c"; }

.fa.fa-long-arrow-left {
  --fa: "\f30a"; }

.fa.fa-long-arrow-right {
  --fa: "\f30b"; }

.fa.fa-apple {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-windows {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-android {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-linux {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-dribbble {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-skype {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-foursquare {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-trello {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-gratipay {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-gittip {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-gittip {
  --fa: "\f184"; }

.fa.fa-sun-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-sun-o {
  --fa: "\f185"; }

.fa.fa-moon-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-moon-o {
  --fa: "\f186"; }

.fa.fa-vk {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-weibo {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-renren {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-pagelines {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-stack-exchange {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-arrow-circle-o-right {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-arrow-circle-o-right {
  --fa: "\f35a"; }

.fa.fa-arrow-circle-o-left {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-arrow-circle-o-left {
  --fa: "\f359"; }

.fa.fa-caret-square-o-left {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-caret-square-o-left {
  --fa: "\f191"; }

.fa.fa-toggle-left {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-toggle-left {
  --fa: "\f191"; }

.fa.fa-dot-circle-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-dot-circle-o {
  --fa: "\f192"; }

.fa.fa-vimeo-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-vimeo-square {
  --fa: "\f194"; }

.fa.fa-try {
  --fa: "\e2bb"; }

.fa.fa-turkish-lira {
  --fa: "\e2bb"; }

.fa.fa-plus-square-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-plus-square-o {
  --fa: "\f0fe"; }

.fa.fa-slack {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-wordpress {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-openid {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-institution {
  --fa: "\f19c"; }

.fa.fa-bank {
  --fa: "\f19c"; }

.fa.fa-mortar-board {
  --fa: "\f19d"; }

.fa.fa-yahoo {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-google {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-reddit {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-reddit-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-reddit-square {
  --fa: "\f1a2"; }

.fa.fa-stumbleupon-circle {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-stumbleupon {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-delicious {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-digg {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-pied-piper-pp {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-pied-piper-alt {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-drupal {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-joomla {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-behance {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-behance-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-behance-square {
  --fa: "\f1b5"; }

.fa.fa-steam {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-steam-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-steam-square {
  --fa: "\f1b7"; }

.fa.fa-automobile {
  --fa: "\f1b9"; }

.fa.fa-cab {
  --fa: "\f1ba"; }

.fa.fa-spotify {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-deviantart {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-soundcloud {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-file-pdf-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-pdf-o {
  --fa: "\f1c1"; }

.fa.fa-file-word-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-word-o {
  --fa: "\f1c2"; }

.fa.fa-file-excel-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-excel-o {
  --fa: "\f1c3"; }

.fa.fa-file-powerpoint-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-powerpoint-o {
  --fa: "\f1c4"; }

.fa.fa-file-image-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-image-o {
  --fa: "\f1c5"; }

.fa.fa-file-photo-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-photo-o {
  --fa: "\f1c5"; }

.fa.fa-file-picture-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-picture-o {
  --fa: "\f1c5"; }

.fa.fa-file-archive-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-archive-o {
  --fa: "\f1c6"; }

.fa.fa-file-zip-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-zip-o {
  --fa: "\f1c6"; }

.fa.fa-file-audio-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-audio-o {
  --fa: "\f1c7"; }

.fa.fa-file-sound-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-sound-o {
  --fa: "\f1c7"; }

.fa.fa-file-video-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-video-o {
  --fa: "\f1c8"; }

.fa.fa-file-movie-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-movie-o {
  --fa: "\f1c8"; }

.fa.fa-file-code-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-file-code-o {
  --fa: "\f1c9"; }

.fa.fa-vine {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-codepen {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-jsfiddle {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-life-bouy {
  --fa: "\f1cd"; }

.fa.fa-life-buoy {
  --fa: "\f1cd"; }

.fa.fa-life-saver {
  --fa: "\f1cd"; }

.fa.fa-support {
  --fa: "\f1cd"; }

.fa.fa-circle-o-notch {
  --fa: "\f1ce"; }

.fa.fa-rebel {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-ra {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-ra {
  --fa: "\f1d0"; }

.fa.fa-resistance {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-resistance {
  --fa: "\f1d0"; }

.fa.fa-empire {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-ge {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-ge {
  --fa: "\f1d1"; }

.fa.fa-git-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-git-square {
  --fa: "\f1d2"; }

.fa.fa-git {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-hacker-news {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-y-combinator-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-y-combinator-square {
  --fa: "\f1d4"; }

.fa.fa-yc-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-yc-square {
  --fa: "\f1d4"; }

.fa.fa-tencent-weibo {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-qq {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-weixin {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-wechat {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-wechat {
  --fa: "\f1d7"; }

.fa.fa-send {
  --fa: "\f1d8"; }

.fa.fa-paper-plane-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-paper-plane-o {
  --fa: "\f1d8"; }

.fa.fa-send-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-send-o {
  --fa: "\f1d8"; }

.fa.fa-circle-thin {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-circle-thin {
  --fa: "\f111"; }

.fa.fa-header {
  --fa: "\f1dc"; }

.fa.fa-futbol-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-futbol-o {
  --fa: "\f1e3"; }

.fa.fa-soccer-ball-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-soccer-ball-o {
  --fa: "\f1e3"; }

.fa.fa-slideshare {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-twitch {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-yelp {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-newspaper-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-newspaper-o {
  --fa: "\f1ea"; }

.fa.fa-paypal {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-google-wallet {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-cc-visa {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-cc-mastercard {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-cc-discover {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-cc-amex {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-cc-paypal {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-cc-stripe {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-bell-slash-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-bell-slash-o {
  --fa: "\f1f6"; }

.fa.fa-trash {
  --fa: "\f2ed"; }

.fa.fa-copyright {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-eyedropper {
  --fa: "\f1fb"; }

.fa.fa-area-chart {
  --fa: "\f1fe"; }

.fa.fa-pie-chart {
  --fa: "\f200"; }

.fa.fa-line-chart {
  --fa: "\f201"; }

.fa.fa-lastfm {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-lastfm-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-lastfm-square {
  --fa: "\f203"; }

.fa.fa-ioxhost {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-angellist {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-cc {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-cc {
  --fa: "\f20a"; }

.fa.fa-ils {
  --fa: "\f20b"; }

.fa.fa-shekel {
  --fa: "\f20b"; }

.fa.fa-sheqel {
  --fa: "\f20b"; }

.fa.fa-buysellads {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-connectdevelop {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-dashcube {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-forumbee {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-leanpub {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-sellsy {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-shirtsinbulk {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-simplybuilt {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-skyatlas {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-diamond {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-diamond {
  --fa: "\f3a5"; }

.fa.fa-transgender {
  --fa: "\f224"; }

.fa.fa-intersex {
  --fa: "\f224"; }

.fa.fa-transgender-alt {
  --fa: "\f225"; }

.fa.fa-facebook-official {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-facebook-official {
  --fa: "\f09a"; }

.fa.fa-pinterest-p {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-whatsapp {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-hotel {
  --fa: "\f236"; }

.fa.fa-viacoin {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-medium {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-y-combinator {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-yc {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-yc {
  --fa: "\f23b"; }

.fa.fa-optin-monster {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-opencart {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-expeditedssl {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-battery-4 {
  --fa: "\f240"; }

.fa.fa-battery {
  --fa: "\f240"; }

.fa.fa-battery-3 {
  --fa: "\f241"; }

.fa.fa-battery-2 {
  --fa: "\f242"; }

.fa.fa-battery-1 {
  --fa: "\f243"; }

.fa.fa-battery-0 {
  --fa: "\f244"; }

.fa.fa-object-group {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-object-ungroup {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-sticky-note-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-sticky-note-o {
  --fa: "\f249"; }

.fa.fa-cc-jcb {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-cc-diners-club {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-clone {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hourglass-o {
  --fa: "\f254"; }

.fa.fa-hourglass-1 {
  --fa: "\f251"; }

.fa.fa-hourglass-2 {
  --fa: "\f252"; }

.fa.fa-hourglass-3 {
  --fa: "\f253"; }

.fa.fa-hand-rock-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-rock-o {
  --fa: "\f255"; }

.fa.fa-hand-grab-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-grab-o {
  --fa: "\f255"; }

.fa.fa-hand-paper-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-paper-o {
  --fa: "\f256"; }

.fa.fa-hand-stop-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-stop-o {
  --fa: "\f256"; }

.fa.fa-hand-scissors-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-scissors-o {
  --fa: "\f257"; }

.fa.fa-hand-lizard-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-lizard-o {
  --fa: "\f258"; }

.fa.fa-hand-spock-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-spock-o {
  --fa: "\f259"; }

.fa.fa-hand-pointer-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-pointer-o {
  --fa: "\f25a"; }

.fa.fa-hand-peace-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-hand-peace-o {
  --fa: "\f25b"; }

.fa.fa-registered {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-creative-commons {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-gg {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-gg-circle {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-odnoklassniki {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-odnoklassniki-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-odnoklassniki-square {
  --fa: "\f264"; }

.fa.fa-get-pocket {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-wikipedia-w {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-safari {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-chrome {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-firefox {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-opera {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-internet-explorer {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-television {
  --fa: "\f26c"; }

.fa.fa-contao {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-500px {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-amazon {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-calendar-plus-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-calendar-plus-o {
  --fa: "\f271"; }

.fa.fa-calendar-minus-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-calendar-minus-o {
  --fa: "\f272"; }

.fa.fa-calendar-times-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-calendar-times-o {
  --fa: "\f273"; }

.fa.fa-calendar-check-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-calendar-check-o {
  --fa: "\f274"; }

.fa.fa-map-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-map-o {
  --fa: "\f279"; }

.fa.fa-commenting {
  --fa: "\f4ad"; }

.fa.fa-commenting-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-commenting-o {
  --fa: "\f4ad"; }

.fa.fa-houzz {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-vimeo {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-vimeo {
  --fa: "\f27d"; }

.fa.fa-black-tie {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-fonticons {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-reddit-alien {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-edge {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-credit-card-alt {
  --fa: "\f09d"; }

.fa.fa-codiepie {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-modx {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-fort-awesome {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-usb {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-product-hunt {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-mixcloud {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-scribd {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-pause-circle-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-pause-circle-o {
  --fa: "\f28b"; }

.fa.fa-stop-circle-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-stop-circle-o {
  --fa: "\f28d"; }

.fa.fa-bluetooth {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-bluetooth-b {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-gitlab {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-wpbeginner {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-wpforms {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-envira {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-wheelchair-alt {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-wheelchair-alt {
  --fa: "\f368"; }

.fa.fa-question-circle-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-question-circle-o {
  --fa: "\f059"; }

.fa.fa-volume-control-phone {
  --fa: "\f2a0"; }

.fa.fa-asl-interpreting {
  --fa: "\f2a3"; }

.fa.fa-deafness {
  --fa: "\f2a4"; }

.fa.fa-hard-of-hearing {
  --fa: "\f2a4"; }

.fa.fa-glide {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-glide-g {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-signing {
  --fa: "\f2a7"; }

.fa.fa-viadeo {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-viadeo-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-viadeo-square {
  --fa: "\f2aa"; }

.fa.fa-snapchat {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-snapchat-ghost {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-snapchat-ghost {
  --fa: "\f2ab"; }

.fa.fa-snapchat-square {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-snapchat-square {
  --fa: "\f2ad"; }

.fa.fa-pied-piper {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-first-order {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-yoast {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-themeisle {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-google-plus-official {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-google-plus-official {
  --fa: "\f2b3"; }

.fa.fa-google-plus-circle {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-google-plus-circle {
  --fa: "\f2b3"; }

.fa.fa-font-awesome {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-fa {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-fa {
  --fa: "\f2b4"; }

.fa.fa-handshake-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-handshake-o {
  --fa: "\f2b5"; }

.fa.fa-envelope-open-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-envelope-open-o {
  --fa: "\f2b6"; }

.fa.fa-linode {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-address-book-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-address-book-o {
  --fa: "\f2b9"; }

.fa.fa-vcard {
  --fa: "\f2bb"; }

.fa.fa-address-card-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-address-card-o {
  --fa: "\f2bb"; }

.fa.fa-vcard-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-vcard-o {
  --fa: "\f2bb"; }

.fa.fa-user-circle-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-user-circle-o {
  --fa: "\f2bd"; }

.fa.fa-user-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-user-o {
  --fa: "\f007"; }

.fa.fa-id-badge {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-drivers-license {
  --fa: "\f2c2"; }

.fa.fa-id-card-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-id-card-o {
  --fa: "\f2c2"; }

.fa.fa-drivers-license-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-drivers-license-o {
  --fa: "\f2c2"; }

.fa.fa-quora {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-free-code-camp {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-telegram {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-thermometer-4 {
  --fa: "\f2c7"; }

.fa.fa-thermometer {
  --fa: "\f2c7"; }

.fa.fa-thermometer-3 {
  --fa: "\f2c8"; }

.fa.fa-thermometer-2 {
  --fa: "\f2c9"; }

.fa.fa-thermometer-1 {
  --fa: "\f2ca"; }

.fa.fa-thermometer-0 {
  --fa: "\f2cb"; }

.fa.fa-bathtub {
  --fa: "\f2cd"; }

.fa.fa-s15 {
  --fa: "\f2cd"; }

.fa.fa-window-maximize {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-window-restore {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-times-rectangle {
  --fa: "\f410"; }

.fa.fa-window-close-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-window-close-o {
  --fa: "\f410"; }

.fa.fa-times-rectangle-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-times-rectangle-o {
  --fa: "\f410"; }

.fa.fa-bandcamp {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-grav {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-etsy {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-imdb {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-ravelry {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-eercast {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-eercast {
  --fa: "\f2da"; }

.fa.fa-snowflake-o {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

.fa.fa-snowflake-o {
  --fa: "\f2dc"; }

.fa.fa-superpowers {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-wpexplorer {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa.fa-meetup {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

a.badge-primary:focus,
a.badge-primary.focus {
	outline: 0;
	box-shadow: none;
}

.form-control:disabled {
	background-color: white;
	opacity: 0.5;
}

.form-check-input:checked {
	background-color: #135c94;
	border-color: #135c94;
}

.form-check-input:checked[type='radio'] {
	background-image: none;
}

// Bootstrap shims for 5 to 5 migration

.float-right {
	float: right;
}
.float-left {
	float: left;
}
.btn-block {
	width: 100%;
}
.form-group {
	margin-bottom: 1rem;
}
.navbar {
	height: 56px !important;
}
input.form-control {
	height: 36px !important;
}

.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
}

.close:hover {
	color: #000;
	text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
	opacity: 0.75;
}

button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
}

a.close.disabled {
	pointer-events: none;
}

.form-control-label {
	margin-bottom: 0.5rem;
}

.alert {
	line-height: 1.2em;
}

.alert-slim {
	padding: 10px 10px 10px 10px;
}

.alert-bg-gray {
	background-color: #dadde0;
}

.alert-bg-green {
	background-color: #c1dec4;
}

// Margin utilities extention
.mr-8 {
	margin-right: 8px;
}

input.form-range[type='range']::-webkit-slider-runnable-track {
	background: #a7b8c6;
}

input.form-range[type='range']::-webkit-slider-thumb {
	background: #1d6399;
}
// input.form-range[type='range']::-webkit-slider-thumb {
// 	width: 25px; /* Set a specific slider handle width */
// 	height: 25px; /* Slider handle height */
// 	position: relative;
// 	top: -4px;
// }

// Shimmer button effect

.btn-shimmer {
	position: relative;
	overflow: hidden;
}

.btn-shimmer::after {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 200%;
	height: 200%;
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
	transform: rotate(30deg);
	animation: shimmer 3s infinite;
}

@keyframes shimmer {
	from {
		transform: rotate(30deg) translateX(-100%);
	}
	to {
		transform: rotate(30deg) translateX(100%);
	}
}

// General Form formatting
label {
	font-weight: bold;
}

.phone-number-code-td {
	width: 80px;
}
.phone-number-input-td {
	max-width: 275px;
}

.clear-link {
	float: right;
	color: #0275d5;
	cursor: pointer;
}
.clear-link:hover {
	color: #095a9c;
}
.options-block {
	margin-bottom: 22px;
}
.options-block-radio-lg {
	input[type='radio'] {
		width: 20px;
		height: 20px;
		margin-right: 10px;
		box-shadow: none;
		border-color: #a1a6ab;
	}
	label {
		position: relative;
		top: 2px;
	}
}

.options-section-header {
	font-size: 1.4rem;
	color: #135c94;
	font-weight: 400;
}
.options-section-header-lg {
	color: #135c94;
	font-size: 1.4em;
	margin-bottom: 15px;
}
.options-section-header-goldbar {
	background: #a7a161;
	color: white;
	border: 1px solid #a7a161;
	border-radius: 8px;
	padding: 0px 8px;
}

.options-header-large {
	font-size: 1.4em;
	color: #135c94;
}
.options-header {
	font-weight: bold;
	color: #135c94;
	margin-bottom: 12px;
}

.options-expand-header {
	font-size: 1.4em;
	color: #135c94;
	cursor: pointer;
}
.options-expand-chevron {
	color: gray;
	text-align: right;
}
.options-expand-error {
	color: chocolate;
	.fe-label {
		color: chocolate;
	}
}

.options-note-gold {
	line-height: 1.2em;
	color: #8f826c;
}
.options-title {
	font-weight: bold;
	line-height: 1.2em;
	margin-bottom: 8px;
}
.options-title-inline {
	display: inline-block;
	font-weight: bold;
	line-height: 1.2em;
	margin-bottom: 8px;
}
.options-note {
	margin-top: 8px;
	line-height: 1.2em;
	font-size: 1em;
}
.options-note-sm {
	margin-top: 8px;
	font-weight: 400;
	font-size: 1em;
	line-height: 1.1em;
	color: #8a8377;
}
.options-checkbox {
	margin-top: 10px;
}
.options-radio-inline {
	display: inline-block;
}
.options-alert {
	line-height: 1.2em;
}
.options-textarea {
	padding: 8px;
	height: 120px;
	line-height: 1.2em;
	width: 100%;
	border-color: lightgray;
	border-radius: 0.25rem;
}
// Use options-textarea-dynamic with manual resize code
.options-textarea-dynamic {
	height: 40px;
}
// Use options-textarea-resizer with appTextAreaResizer directive
.options-textarea-resizer {
	height: 54px;
}
.options-switch {
	margin-bottom: 10px;
}
.options-switch-hint {
	height: 20px;
	font-size: 0.8rem;
	margin-top: -10px;
	color: gray;
}

.dialog-no-records {
	text-align: center;
	margin: 35px auto;
	line-height: 1.1em;
	font-size: 2em;
	width: 200px;
	color: chocolate;
}

/* Tooltip Styles */

.tooltip-section-header {
	margin-top: 30px;
	margin-bottom: 30px !important;
}

.tooltip-block {
	background-color: #eee;
	margin-bottom: 15px;
}

.tooltip-info-text {
	// font-size: 90%;
	line-height: 1.3em !important;
	color: #818a91;
	display: inherit;
}

.tooltip-divider {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px dashed #bbb;
	margin: 2em 0;
	padding: 0;
}

.tooltip-step {
	color: chocolate;
}

.tooltip-example {
	color: #ab6767;
}

/* Login Form Styles */

.form-signin {
	max-width: 350px;
	padding: 15px;
	margin: 0 auto;
	background-color: white;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
	margin-bottom: 10px;
}

.form-signin .checkbox {
	font-weight: normal;
}

.form-signin .form-control {
	position: relative;
	// height: auto;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 10px;
	font-size: 16px;
}

.form-signin .form-control:focus {
	z-index: 2;
}

.form-signin input[type='email'] {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.form-signin input[type='password'] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

/* Form Element Styles */

.form-control {
	padding: 0.5rem 0.5rem;
	border: 1px solid #d6d6d6;
}

.form-label-bold {
	font-weight: bold;
	line-height: 2.5em;
}

.form-checkbox-label {
	font-weight: bold;
	display: inline-block;
}

.form-notes-display {
	white-space: pre-wrap;
	font-size: 0.9em;
	line-height: 1.3em;
	margin-bottom: 6px;
	border: 1px solid lightslategray;
	padding: 4px;
	border-radius: 8px;
	min-width: 250px;
	max-width: 600px;
	max-height: 130px;
	overflow: hidden;
	overflow-y: auto;
	background-color: #efefef;
}

// select.form-control {
//   padding: 0 0;
// }

.input-label {
	padding-bottom: 8px;
}

.input-required {
	/*color: #818a91;*/
	color: chocolate;

	select {
		color: chocolate !important;
		border-color: chocolate !important;
		option {
			color: chocolate !important;
		}
	}

	.options-title {
		color: chocolate !important;
	}
	.options-textarea {
		border-color: chocolate !important;
	}
	.form-control {
		border-color: chocolate !important;
		box-shadow: none;
	}
	.p-autocomplete {
		input {
			border-color: chocolate;
			box-shadow: none;
			color: chocolate;
		}
	}
	.p-select,
	.p-dropdown {
		border-color: chocolate;
		box-shadow: none;
	}
	.p-select-label {
		color: chocolate !important;
	}
	.p-multiselect {
		border-color: chocolate;
		box-shadow: none;
	}
	.p-multiselect-label {
		color: chocolate !important;
	}
	.picker-icon {
		border-color: chocolate;
	}
	.datetime-icon {
		color: chocolate;
	}
	.date-picker-input {
		color: chocolate !important;
	}
	.p-dropdown-trigger-icon {
		color: chocolate;
	}
	.byweekday-option {
		border-color: chocolate !important;
	}
}
.form-input-required {
	border-color: chocolate;
	box-shadow: none;
}
.input-optional {
	color: gray;
}

.input-invalid {
	/*color: #818a91;*/
	color: firebrick;
}

.input-field-note {
	color: #888;
	padding-top: 5px;
}

.input-horizontal-label {
	color: #555;
	margin-top: 5px;
}

.input-horizontal-row {
	margin-top: 10px;
}

.row-divider {
	margin-top: 25px;
	margin-bottom: 15px;
}

.country-code {
	color: #878491;
	padding-top: 5px;
	font-size: 1.3em;
	font-weight: bold;
}

/* Edit Form Global Styles */

.edit-record-not-found {
	margin-top: 50px;
	margin-bottom: 50px;
	color: rgb(147, 158, 177);
	font-size: 2.5em;
	/* font-weight: bold;
	line-height: 1.2em; */
	text-align: center;
}

.edit-form-block {
	margin-top: 15px;
}

/* TSFORM Classes */
.tsf-info-btn {
	margin-left: 5px;
}
.tsf-save-btn {
	margin-left: 10px;
}
.tsf-time-abbrv {
	margin-left: 5px;
}

.has-pointer {
	cursor: pointer;
}

/* PrimeNG Forms */

p-dropdown.ng-dirty.ng-invalid > .ui-dropdown {
	border-color: chocolate;
	box-shadow: none;
}

input.form-control.ng-dirty.ng-invalid {
	border-color: chocolate;
	box-shadow: none;
}

// .p-autocomplete-mod {
// 		width: 284px;
// }
// @media(min-width:321px) {
// 	.p-autocomplete-mod {
// 		width: 338px;
// 	}
// }
// @media(min-width:376px) {
// 	.p-autocomplete-mod {
// 		width: 370px;
// 	}
// }
// @media(min-width:600px) {
// 	.p-autocomplete-mod {
// 		width: 410px;
// 	}
// }

// Copied from bootstrap input
.ui-autocomplete .ui-autocomplete-input {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition:
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}
.ui-autocomplete {
	width: 100%;
}

// Notification

// Multi-list Dropdown Selections

.multi-list-block {
	font-weight: bold;
	color: slategray;
	padding: 6px 8px;
	margin: 5px 0px 25px 0px;
	border: 1px solid lightgray;
	border-radius: 5px;
	cursor: default;
}
.multi-list-box {
	height: 85px;
	overflow-y: scroll;
}

// Emp List Block

.item-list-block {
	font-weight: bold;
	color: slategray;
	padding: 6px 8px;
	margin: 5px 0px 5px 0px;
	border: 1px solid #ced4da;
	border-radius: 5px;
	cursor: default;

	.del-btn {
		margin: 0px 4px 0px 12px;
		color: #c5c5c5;
		cursor: pointer;
	}
	.del-btn:hover {
		color: #9c9a9a;
	}
	.item-label {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
// .item-list-block:hover {
// 	border-color: #2196F3;
// }
.item-list-box {
	height: 85px;
	overflow-y: scroll;
	scrollbar-width: none;
}
.item-list-box::-webkit-scrollbar {
	display: none;
	width: 0px; /* Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}
// Checkbox
.checkbox-card {
	padding: 8px;
	border: 1px solid gray;
	border-radius: 8px;
}
.checkbox-wrap {
	width: 35px;
}
.checkbox-child {
	margin-left: 30px;
}
.checkbox-item {
	margin-bottom: 10px;
}
.checkbox-label-wrap {
	margin-top: 2px;
	width: 100%;
	cursor: pointer;
}
.checkbox-label-text {
	display: inline-block;
	font-weight: bold;
}
.checkbox-disabled {
	pointer-events: none;
	color: gray !important;
	i {
		pointer-events: all;
	}
}

.radio-label-pad {
	padding-top: 2px;
}

// Disabled Input
.input-disabled {
	border: 1px solid #ddd;
	border-radius: 3px;
	padding: 5px 10px 5px 10px;
	background-color: #eee !important;
	color: #666 !important;
	opacity: 1 !important;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

// Disabled Textarea
.textarea-disabled {
	border: 1px solid #ddd;
	border-radius: 3px;
	padding: 5px 10px 5px 10px;
	background-color: #eee !important;
	color: #666 !important;
	opacity: 1 !important;
	white-space: pre-wrap;
}

// Email Box
.email-box-x {
	margin-top: 2px;
	margin-right: 2px;
	cursor: pointer;
}

.btn-outline-main {
	color: #135c94;
	border-color: #135c94;
}
.btn-outline-main:hover {
	color: white !important;
	background-color: #135c94 !important;
}
.btn-outline-main:active {
	color: white !important;
	background-color: #135c94 !important;
}

// Bottom margin
.btn-mb-8 {
	margin-bottom: 8px;
}

// Border color for dropdown
.p-select:not(.p-disabled).p-focus {
	border-color: #86b7fe !important;
}
// Border color for multi-select
.p-multiselect:not(.p-disabled).p-focus {
	border-color: #86b7fe !important;
}
// Border color for inputs
.p-inputtext:enabled:focus {
	border-color: #86b7fe !important;
}
// Make overlays 100% of their container width
.p-select-overlay {
	width: 100% !important;
}
// Label for dropdown / multiselect input menu
.p-select-label {
	padding: 6px !important;
}
// Padding for dropdown / multiselect overlay
.p-select-list {
	padding: 8px !important;
}
// Add padding to dropdown / multiselect items
li.p-select-option {
	padding: 0.3rem 0.5rem;
}
// Removes focus box shadow from input components
.p-focus {
	box-shadow: none !important;
}
// Input icon padding
.p-input-icon-left > .p-inputtext {
	padding-left: 2.5rem;
}
// Password component
.p-password {
	width: 100%;
}

//////////////////
// Autocomplete //
//////////////////

.p-autocomplete {
	width: 100% !important;
	.p-inputtext {
		width: 100%;
	}
	.p-autocomplete-panel {
		margin-bottom: 25px;
	}
	.p-autocomplete-option {
		white-space: normal !important;
	}
	input {
		height: calc(1.5em + 0.75rem + 2px);
		border-color: #d6d6d6;
		border-radius: 0.25rem;
		height: 36px;
	}
}

// Autocomplete small cap dropdown - forces small caps in panel for tag component
.p-ac-smallcap-wrap {
	li.p-autocomplete-option {
		text-transform: uppercase !important;
	}
	.p-inputwrapper-filled {
		.p-autocomplete-input {
			text-transform: uppercase !important;
		}
	}
}

//////////////
// Calendar //
//////////////

.p-calendar {
	width: 100%;
}

//////////////
// Checkbox //
//////////////

.p-checkbox {
	margin-right: 8px;
	border-radius: 4px;
	.p-checkbox-box {
		margin-top: -2px;
		.p-checkbox-icon {
			font-size: 12px;
		}
	}
}
.p-checkbox-checked {
	.p-checkbox-box {
		background: #3e76a3 !important;
		border-color: #3e76a3 !important;
	}
}

.p-checkbox.p-disabled .p-checkbox-box {
	background: #e2e8f0 !important;
	border-color: #cbd5e1 !important;
}

//////////////////
// Context Menu //
//////////////////

.p-contextmenu {
	border-radius: 8px;
}
p-contextmenu {
	ul {
		margin-bottom: 0px;
		padding: 4px;
	}
	.p-menuitem-text {
		color: rgb(94, 94, 94);
	}
	.p-menuitem-content:hover {
		background-color: #edf0f2;
	}
}

///////////////
// DataTable //
///////////////

.p-datatable .p-datatable-header {
	background: none;
	color: #495057;
	border: 1px solid #e9ecef;
	border-width: 1px 0 1px 0;
	padding: 1rem 1rem;
	font-weight: 600;
}

#sync-log-table {
	.p-datatable-header {
		padding: 0px 0px 12px 0px;
	}
}

.p-datatable .p-datatable-tbody > tr > td {
	padding: 6px 8px;
}

.p-datatable .p-datatable-thead > tr > th {
	padding: 6px 8px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
	background: #f2f1f5;
}

.p-datatable .p-datatable-tbody > tr {
	background: #ffffff;
}

////////////
// Dialog //
////////////

app-click-to-call {
	.p-dialog {
		width: 90%;
	}
}

@media (min-width: 500px) {
	app-click-to-call {
		.p-dialog {
			width: 460px;
		}
	}
}

.p-dialog {
	border-radius: 10px;
	background-color: #dddddd;
	// box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	// box-shadow: 0 1px 8px 0 #878994;
}
.p-dialog-header-icon {
	border-radius: 50%;
}
.p-dialog-header {
	background: #ddd !important;
	// padding: 1rem 1.5rem 0.5rem 1.5rem !important;
	padding-top: 1rem !important;
	padding-bottom: 0.5rem !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
}
.p-dialog-content {
	background: #ddd !important;
	color: #495057;
	padding: 0.5rem 1.5rem 1.5rem 1.5rem;
	border-bottom-left-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}
.p-dialog-noheader {
	.p-dialog-header {
		display: none;
	}
	.p-dialog-content {
		border-bottom-left-radius: 10px !important;
		border-bottom-right-radius: 10px !important;
		border-top-left-radius: 10px !important;
		border-top-right-radius: 10px !important;
		padding: 15px !important;
	}
}
.p-dialog-whitebackground {
	.p-dialog-header {
		background: #fff !important;
	}
	.p-dialog-content {
		background: #fff !important;
	}
}

// Hide the overlay mask when no-mask-dialog class is used for maskStyleClass
.no-mask-dialog.p-overlay-mask {
	background-color: transparent !important;
}

// Possible fix for scrollbar issue
.p-dialog-content {
	width: 100%;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
.p-dialog-content::-webkit-scrollbar {
	display: none;
}

// This will adjust the mask so it doesn't get twice as dark when multiple p-dialogs open
.p-dialog-mask-reset {
	.p-dialog-mask {
		background-color: rgba(0, 0, 0, 0.225) !important; /* Adjust the alpha value (0.5) for transparency */
	}
}
.p-dialog-close-button:hover {
	background-color: #e9ecef !important;
}

///////////////////////
// Dropdown / Select //
///////////////////////

// Constrain width and pad text label
.p-dropdown,  // Remove .p-dropdown when migration is complete
.p-select {
	width: 100%;
	.p-select-label {
		padding: 6px 9px !important;
	}
}

// Make option items wrap normally
.p-select-option {
	white-space: normal !important;
}

// Make color and background for hover grayish
.p-select-option:hover {
	color: #495057;
	background: #e9ecef;
}

// Patch to make the group header smaller
li.p-dropdown-item-group {
	height: 40px !important;
}

// Force overlay panel to be below the selector when not appended to body
p-select,
p-multiselect {
	.p-overlay {
		transform-origin: top !important; /* Set the animation to originate from the top */
		top: 38px !important; /* Always position the overlay below the selector */
		bottom: auto !important; /* Disable the auto adjustment for mobile */
	}
}

// DEPRECATED 2025-01-23
// .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
// 	margin: 3px;
// 	padding: 0.25rem 0.5rem;
// 	border-radius: 5px;
// 	text-align: left;
// 	white-space: normal !important;
// }

// DEPRECATED 2025-01-23
// .p-dropdown-items-wrapper {
// 	padding: 5px;
// }

// DEPRECATED 2025-01-23
// .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
// 	font-weight: bold;
// 	cursor: default;
// 	color: white;
// 	font-size: 1.3em;
// 	background: #65696e;
// 	padding: 5px 10px 5px 10px;
// 	border-radius: 3px;
// }

////////////
// Images //
////////////

.p-image {
	img {
		border-radius: 6px;
	}
	.p-image-preview-indicator {
		border-radius: 6px;
	}
}

.p-image-error {
	.p-image img {
		border: 4px solid firebrick !important;
	}
}

// Image toolbar (customized for image previewing)
.p-image-toolbar {
	z-index: 10000;
	background-color: black;
	border-radius: 12px;
}

//////////////////
// Input Switch //
//////////////////

.p-toggleswitch.p-toggleswitch-checked .p-toggleswitch-slider {
	background: #59b759 !important;
}

/////////////////
// Multiselect //
/////////////////

.p-multiselect {
	width: 100%;
	.p-multiselect-label {
		padding: 6px 9px;
	}
	.p-multiselect-panel {
		margin-bottom: 25px;
	}
}

// Multi-select option item styles
.p-multiselect-option {
	line-height: 1.1rem;
	white-space: normal !important;
}

// Scheduuler Shift Option Picker
.shift-option-radio-wrap {
	display: inline-flex;
	.p-radiobutton {
		margin-right: 0px;
	}
	label {
		margin-left: 0px;
		margin-right: 10px;
		position: relative;
		top: 2px;
		font-weight: 400;
	}
}

// Used in reports to uppercase the tag multi-select filter option
.tag-dd-block {
	.p-multiselect-option {
		text-transform: uppercase;
	}
	.p-multiselect-header {
		input {
			text-transform: uppercase;
		}
	}
	.item-list-block {
		.item-label {
			text-transform: uppercase;
		}
	}
}

///////////////////
// Overlay Panel //
///////////////////

// Overlay Modal / Overlay Modal Top
.p-overlay-modal.p-overlay-top {
	.p-overlay-content {
		margin-top: 56px;
	}
}

// Settings Overlay Panels (Used on Schedules section)
.settings-overlay-panel {
	background: #cfcabc !important;
	border-radius: 6px !important;
}
.settings-overlay-panel.p-popover-flipped::after {
	border-top-color: #cfcabc !important;
}
.settings-overlay-panel.p-popover::after {
	border-bottom-color: #cfcabc;
}

// Header Notification Overlay Panel (Grouped Notification Banners)
.hno-panel {
	// padding-top: 10px;
	background-color: #f2f2f2;
	width: 100%;
	max-width: 450px;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}
.banner-panel {
	background-color: #f2f2f2;
	width: 100%;
	max-width: 350px;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}
@media (max-width: 500px) {
	.banner-panel {
		max-width: 500px;
	}
}

//////////////////
// Radio Button //
//////////////////

.p-radiobutton {
	margin-right: 8px;
	.p-radiobutton-icon {
		background-color: #3e76a3 !important;
	}
}
.p-radioButton-sm {
	.p-radiobutton-box {
		width: 15px;
		height: 15px;
	}
}
.p-radiobutton-checked .p-radiobutton-box {
	background: #3e76a3 !important;
	border-color: #3e76a3 !important;
}

////////////////
// Scroll Top //
////////////////

body.modal-open {
	.p-scrolltop {
		display: none !important;
	}
}
.p-scrolltop {
	border-radius: 50%;
}

//////////////
// Timeline //
//////////////

// Makes sure the timeline event markers justify right and stay flush with the conainer
// on the last timeline event. Could interfere if using the vertical timeline layout.
.urr-report-wrapper {
	.p-timeline-event-opposite,
	.p-timeline-event-content {
		min-width: unset !important;
	}
}

///////////
// Toast //
///////////

.p-toast {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	top: 64px !important;
	right: 14px !important;
	width: 340px !important;
	opacity: 1;
	text-align: left;
}
.p-toast-summary {
	color: white;
	font-weight: 700 !important;
}
.p-toast-detail {
	color: white !important;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.3rem;
}
.p-toast-message-icon {
	color: black;
	width: 24px;
	height: 24px;
}
.p-toast-close-icon {
	color: black;
}
.p-toast-message-success {
	.p-toast-message-content {
		color: white;
		background-color: #28a745;
	}
	border-color: #28a745 !important;
}
.p-toast-message-warn {
	.p-toast-message-content {
		color: white;
		background-color: #d39e00;
	}
	border-color: #d39e00 !important;
}

.p-toast-message-error {
	.p-toast-message-content {
		color: white;
		background-color: #d9534f;
	}
	border-color: #d9534f !important;
}

.p-toast-message-info {
	.p-toast-message-content {
		color: white;
		background-color: #2399e5;
	}
	border-color: #2399e5 !important;
}

/////////////
// Tooltip //
/////////////

// Help Video Link Tooltip
.hvl-tooltip {
	box-shadow:
		2px 4px -1px rgb(0 0 0 / 20%),
		0 4px 3px 0 rgb(0 0 0 / 14%),
		0 1px 4px 0 rgb(0 0 0 / 12%);
	.p-tooltip-text {
		background-color: #135c94; // #007bff;
	}
	.p-tooltip-text {
		padding: 0.15rem 0.5rem;
		font-size: 0.9rem;
	}
}

// Small Tooltips
.small-tooltip {
	.p-tooltip-text {
		padding: 0.15rem 0.5rem;
		font-size: 0.8rem;
	}
}

///////////////////
// Miscellaneous //
///////////////////

// File upload button
.p-fileupload-choose-button {
	height: 36px;
	// background-color: #198754 !important; //  #59b759 !important;
}

// HTML Editor (Quill)
.ql-container {
	font-size: 14px;
}
.p-dialog-htmleditor {
	width: 96%;
	max-width: 800px;
	.p-dialog-content {
		padding: 0px 15px 15px 15px !important;
	}
}

////////////
// Colors //
////////////

// Colors for use in multi-select and drodpwn option lists (company selecctor)
.png-clr-blue {
	color: rgb(20, 115, 230);
}
.png-clr-green {
	color: green;
}
.png-clr-chocolate {
	color: chocolate;
}
.png-clr-firebrick {
	color: firebrick;
}
.png-clr-teal {
	color: #1e93bb;
}
.png-clr-gold {
	color: #a99710;
}
.png-clr-gray {
	color: gray;
}

// Color for selected item in a dropdown (need to unset any png colors used in dashboard company selector)
.p-select-option.p-select-option-selected {
	color: #ddd !important;
	background: #3e76a3 !important;
	border-color: #3e76a3 !important;
	.png-clr-blue,
	.png-clr-firebrick,
	.png-clr-gold,
	.png-clr-gray,
	.png-clr-green,
	.png-clr-teal {
		color: unset !important;
	}
}

@use 'sass:color';

// Section icons
.dropdown-item {
	color: #5e6367;
}
.sec-head-wrap {
	font-size: 1.6rem;
	height: 70px;
}
.sec-head-ico {
	margin-right: 6px;
}
.sec-title-wrap {
	color: black;
}
.sec-title-ico-wrap {
	display: inline-block;
	// margin-left: 8px;
	cursor: pointer;
}
.sec-title-ico {
	color: #007bff;
	position: relative;
	top: -5px;
	font-size: 0.6em;
	width: 30px;
}
.sec-head-title-highlight {
	color: chocolate;
}

.sec-act-ico-wrap {
	float: right;
	cursor: pointer;
}
.sec-act-ico {
	color: #0275d8;
	margin-left: 10px;
}
.sec-act-ico:hover {
	color: #014c8c;
	transform: scale(1.2);
}
.sec-act-ico-disabled {
	color: gray;
}

// Sub-section icons
.sub-head-wrap {
	font-size: 1.2rem;
}
.sub-head-ico-wrap {
	display: inline-block;
	margin-left: -6px;
	cursor: pointer;
}
.sub-head-ico {
	color: #0275d8;
	width: 30px;
	text-align: center;
	display: inline-block;
}
.sub-head-ico:hover {
	color: #014c8c;
}

.sub-head-ico-highlight {
	color: chocolate !important;
}
.sub-head-ico-disabled {
	color: gray;
}

@media (min-width: 1200px) {
	.sub-head-ico:hover {
		transform: scale(1.2);
	}
}

.updated-wrap {
	display: inline;
	font-size: 1rem;
	color: #888;
	font-style: italic;
	margin-left: 4px;
}

.ico-inline-block {
	display: inline-block;
}

// Notification Banners - Begin

// Top Banner

.top-banner {
	padding-top: 8px;
	padding-bottom: 8px;
	text-align: center;
	font-weight: 600;
	cursor: pointer;
	background-color: #dfdcd3;
	border-bottom: 1px solid white;
}

.top-banner-content {
	padding-right: 20px;
	padding-left: 20px;
	height: 24px;
	display: inline-block;
	color: #135c94;
	white-space: nowrap;
}
.top-banner-badge {
	margin-left: 4px;
	color: white;
	background-color: #135c94;
	position: relative;
	top: -2px;
}

// Banner wrapper
.banner-wrap-no-group {
	display: inline-block;
	padding: 4px;
	margin: 8px 8px 0px 0px;
	// border: 1px solid gray;
	// border: 1px solid white;
	border-radius: 6px;
}

.top-banner:hover {
	background-color: color.mix(#dfdcd3, black, 90%);
	// .top-banner-content {
	// 	box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 1px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
	// }
}
.top-banner-warn {
	background-color: chocolate;

	.top-banner-content {
		color: white;
	}
	.top-banner-badge {
		background-color: white;
		color: chocolate;
	}
}
.top-banner-warn:hover {
	background-color: color.mix(chocolate, black, 90%);
}

.top-banner-danger {
	background-color: firebrick;
	.top-banner-content {
		color: white;
	}
	.top-banner-badge {
		background-color: white;
		color: firebrick;
	}
}
.top-banner-danger:hover {
	background-color: color.mix(firebrick, black, 90%);
}

// Notification Banners - End

// Nav Bar Header Icon
.navbar-quicklink {
	cursor: pointer;
	display: inline-flex;
	border: 2px solid #c6c6c6;
	border-radius: 5px;
	margin-right: 10px;
	width: 46px;
	height: 38px;
	font-size: 22px;
	text-align: center;
	align-items: center;
	justify-content: center;
	color: #87898b;
}
.navbar-quicklink:hover {
	background-color: #135c94;
	color: white;
	border: 2px solid #135c94;
}
.navbar-quicklink-active {
	background-color: #59b75a;
	color: white;
	border: 2px solid #59b75a;
}

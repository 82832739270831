// Table Icons
.trans-lock-icon {
	font-size: 1.3rem;
	color: chocolate;
	position: relative;
	top: 4px;
	margin-left: 4px;
}

// Action Icons
.act-ico-wrap {
	display: inline-block;
	font-size: 1.3rem;
	cursor: pointer;
}
.act-ico {
	width: 30px;
	color: #0275d8;
	text-align: center;
}
.act-ico-clr-blue {
	color: #0275d8;
}
.act-ico-clr-red {
	color: firebrick;
}
.act-ico-clr-green {
	color: green;
}
.act-ico-clr-purple {
	color: #5e0080;
}
.act-ico-clr-dark-magenta {
	color: #800000;
}
.act-ico-clr-gray {
	color: #a8a7b3;
}

.act-ico-highlight {
	color: chocolate;
}

.act-ico-disabled {
	color: #a8a7b3;
}
.act-ico-disabled:hover {
	color: #a8a7b3;
}
.act-ico-no-events {
	pointer-events: none;
	cursor: default;
}

.act-ico-rep-error {
	color: firebrick;
}
// Darken icon on hover
.act-ico-dark-hover:hover {
	filter: brightness(0.7);
}
// .act-ico:hover {
// 	transform: scale(1.2);
// }

.thumbtack-ico {
	font-size: 24px;
	position: relative;
	top: 4px;
}

.c2c-ico {
	font-size: 1.1rem;
	width: 26px;
}

// GPS Icons in Time Entries
.gps-icon-block {
	display: inline-block;
	position: relative;
	// top: 3px;
}
.gps-icon {
	width: 22px;
	text-align: center;
}
.gps-icon-pad {
	margin-right: 5px;
}
.gps-icon-dist {
	font-size: 1.3em;
}
.gps-icon-image {
	font-size: 1.3em;
}
.gps-icon-qrcode {
	font-size: 1.3em;
	margin-left: 2px;
}
.gps-icon-notes {
	font-size: 1.3em;
	margin: 0px 4px 0px 2px;
}
.gps-icon-time {
	font-size: 1.3em;
	margin-right: 5px;
}

// Details icons in Time Entries
.cid-match {
	color: green;
}

.tour-ico-interval {
	font-size: 1.3rem;
	position: relative;
	top: 4px;
}

.trans-rep-icon {
	margin-left: 10px;
	font-size: 1.4rem;
	position: relative;
	top: 6px;
	color: #0275d8;
	cursor: pointer;
}

table.fixedHeader-floating {
  background-color: white;
}

table.fixedHeader-floating.no-footer {
  border-bottom-width: 0;
}

table.fixedHeader-locked {
  position: absolute !important;
  background-color: white;
}

@media print {
  table.fixedHeader-floating {
    display: none;
  }
}
html.dark table.fixedHeader-floating {
  background-color: var(--dt-html-background);
}
html.dark table.fixedHeader-locked {
  background-color: var(--dt-html-background);
}

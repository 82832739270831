// Alerts

.alert-no-records {
	color: slategray;
	font-size: 1.6rem;
	font-weight: 600;
	padding: 6px;
}

// Prefs Styles

.prefs-box {
	margin-top: 15px;
}
.close-prefs {
	font-size: 1.2rem !important;
	margin-top: 5px;
	/* border: 1px solid #777; */
}

.close-section {
	font-size: 1.2rem !important;
	margin-top: 5px;
	/* border: 1px solid #777; */
}

.close {
	position: absolute;
	top: 0px;
	right: 5px;
}

.close:focus {
	outline: 0px;
}

// Main Section styles

.section-header {
	font-size: 1.6em;
}

.toggle-help-block {
	margin-bottom: 25px;
}
.toggle-help-icon {
	color: #007bff;
	font-size: 1.3em;
	margin-right: 3px;
}
.section-tooltip-icon {
	float: right;
	font-size: 1.6em;
	/* padding-top: 5px; */
}

.sm-icon-btn {
	color: #0275d8;
	font-size: 1.3em;
	width: 20px;
	margin-right: 8px;
	display: inline-block;
}
.sm-icon-active {
	color: chocolate;
}
.sm-icon-disabled {
	color: rgb(169, 171, 177);
}

.dropdown-menu {
	background-color: #f8f8f8;
}

.dropdown-item:hover {
	background-color: rgb(70, 108, 138);
	color: white !important;
}

.qbo-tooltip-icon {
	float: right;
	font-size: 1.6em;
	vertical-align: middle;
	margin-left: 10px;
}

.section-add-btn {
	margin-top: -8px;
	margin-left: 15px;
}

.section-alert {
	margin-top: 0px;
}

.section-req-title {
	font-size: 1.5em;
}

.section-control-disabled {
	color: rgb(168, 167, 167) !important;
	// cursor: default !important;
}

.section-controls-top {
	font-size: 1.6em;
	position: relative;
	/* top: 5px; */
	/* right: 5px; */
}

.section-control {
	margin-left: 10px;
	color: #0275d8;
	background: transparent;
	border: 0px;
	padding: 0px;
	/* margin: 0 0 0 10; */
}

.section-control:focus {
	outline: 0px;
}

.section-control:hover {
	color: #014c8c;
	cursor: pointer;
}

.section-control:active {
	color: #0275d8;
}

.section-prefs {
	margin-top: -5px;
	position: absolute;
	font-size: 1.5em;
}

.section-updated {
	color: #888;
	font-style: italic;
}

.header-options-btn {
	color: #0275d8;
	background: transparent;
	border: 0px;
	padding: 0px;
}

.header-options-btn:focus {
	outline: 0px;
}

.header-options-btn:hover {
	color: #05467e;
}

.header-options-btn:hover {
	cursor: pointer;
}

// Setup requirements view button

.btn-req-setup {
	margin-top: 5px;
}

// Status / View State Tabs

.status-block {
	margin: 15px 0 0 0;
	font-size: 1.1em;
	text-align: center;
}

@media (min-width: 660px) {
	.status-block {
		margin-top: -55px;
	}
}
.status-item {
	display: inline-block;
	cursor: pointer;
}
.status-title {
	font-size: 1.2em;
	font-weight: 500;
	font-variant: small-caps;
	color: #888;
}
.status-active {
	color: #59b759;
}
.alert-badge {
	font-size: 0.9rem;
}
.alert-badge-active {
	background-color: #59b759 !important;
}
.section-help-header {
	font-size: 1.75rem;
	font-weight: 500;
	line-height: 1.2;
	color: #135c94;
	margin-bottom: 20px;
}

// Nav button
.nav-clear-block {
	position: fixed;
	top: 16px;
	z-index: 1030;
	right: 100px;
}
.nav-clear-btn {
	border: 1px solid chocolate;
	cursor: pointer;
	color: white;
	// font-weight: bold;
	font-size: 1em;
	line-height: 0.8em;
	background-color: chocolate;
	padding: 5px 12px;
	border-radius: 15px;
	position: relative;
}

// Help Section
.help-block {
	margin-top: 18px;
}
// .help-intro {
// 	margin-bottom: 28px;
// }
.help-hl-1 {
	font-weight: 600;
	color: #25517d;
}
.help-hl-2 {
	font-weight: 600;
	color: #606e7c;
}
.help-hl-3 {
	font-weight: 600;
	color: chocolate;
}

.ur-block-note {
	font-style: italic;
	line-height: 1.2em;
	margin-bottom: 25px;
	color: gray;
}
.ur-skip-btn-wrap {
	min-width: 84px;
	position: relative;
	z-index: 5;
}
.ur-skip-btn {
	float: right;
	color: #004c72;
	border: 1px solid #004c72;
	padding: 0px 4px;
	border-radius: 3px;
	font-size: 0.8em;
	font-variant: small-caps;
	min-width: 36px;
	text-align: center;
	position: relative;
	top: 3px;
	cursor: pointer;
}
.ur-skip-btn-on {
	background-color: gray;
	border-color: gray;
	color: white;
}
.ur-basic-label {
	font-weight: bold;
	color: #666;
	line-height: 1.3em;
	position: relative;
	display: inline;
}
.ur-checkbox-row {
	margin-top: 15px;
}
.ur-input-row {
	margin-top: 15px;
}
.ur-range-row {
	margin-top: 15px;
}
.ur-checkbox-label {
	font-weight: bold;
	color: #666;
	line-height: 1.4rem;
	position: relative;
	// top: -2px;
}
.ur-checkbox {
	height: 20px;
	width: 20px;
	float: right;
	margin-right: 8px;
	position: relative;
	top: -3px;
}
.ur-checkbox:checked {
	background-color: #135c94;
	border-color: #135c94;
}

.ur-basic-textarea {
	height: 118px !important;
	line-height: 1.3em;
}
.ur-checkbox-textarea {
	margin-top: 10px;
	margin-bottom: 30px;
}

.ur-cli-block {
	margin-bottom: 0px;
}
.ur-clic-block {
	margin-bottom: -8px;
}
.ur-cli-comment {
	margin-top: 12px;
}

// Separator

.ur-separator {
	display: flex;
	align-items: center;
	text-align: center;
}
.ur-separator::before,
.ur-separator::after {
	content: '';
	flex: 1;
	border-bottom: 1px solid #a0a7ad;
}
.ur-separator::before {
	margin-right: 0.25em;
}
.ur-separator::after {
	margin-left: 0.25em;
}
.ur-report-sep {
	font-weight: bold;
	color: #a0a7ad;
	margin-top: 25px;
	margin-bottom: 25px;
}
.ur-report-sep::before,
.ur-report-sep::after {
	border-bottom: 1px solid #a0a7ad;
}
.ur-form-item-seperator {
	border-top: none;
	border-bottom: 1px solid #a0a7ad;
	margin: 26px 0px;
	opacity: 0.5;
}

// Highlight for form inputs which need info

.ur-input-needs-info {
	border: 1px solid chocolate;
	background-color: #f4e2d7;
}
.ur-skip-input {
	border: 1px solid #ced4da;
	background-color: #e9ecef !important;
	opacity: 1 !important;
}
.ur-skip-textarea {
	border: 1px solid #ced4da;
	height: calc(1.5em + 0.75rem + 2px) !important;
	background-color: #e9ecef !important;
	opacity: 1 !important;
}
.ur-label-disabled {
	color: #b3b3b3 !important; // #cbcbcb;
}

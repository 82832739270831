$primary-color: #004c72; // #1b4a6d;
$secondary-color: #18ace3;

$warn-color: #b3242c;

$btn-valid-color: #5a925a;
$btn-invalid-color: #975256;

$btn-valid-color-disabled: #6db36d;
$btn-invalid-color-disabled: #b36d71;

$form-label: #004c72;

$tts-background-color: #eee;

$btn-menu-color-primary: #135c94; // #004c72;
$btn-menu-color-secondary: #6c757d;
$btn-menu-color-danger: #dc3545;
